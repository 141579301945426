
// Copyright 2012 Google Inc. All rights reserved.
 
(function(){

    var data = {
    "resource": {
      "version":"1",
      
      "macros":[],
      "tags":[],
      "predicates":[],
      "rules":[]
    },
    "runtime":[ 
    ]
    
    ,"blob":{"1":"1"}
    
    
    
    
    
    
    
    
    };
    
    
    
    
    var aa,ba=function(a){var b=0;return function(){return b<a.length?{done:!1,value:a[b++]}:{done:!0}}},ca=typeof Object.defineProperties=="function"?Object.defineProperty:function(a,b,c){if(a==Array.prototype||a==Object.prototype)return a;a[b]=c.value;return a},ea=function(a){for(var b=["object"==typeof globalThis&&globalThis,a,"object"==typeof window&&window,"object"==typeof self&&self,"object"==typeof global&&global],c=0;c<b.length;++c){var d=b[c];if(d&&d.Math==Math)return d}throw Error("Cannot find global object");
    },fa=ea(this),ha=function(a,b){if(b)a:{for(var c=fa,d=a.split("."),e=0;e<d.length-1;e++){var f=d[e];if(!(f in c))break a;c=c[f]}var k=d[d.length-1],l=c[k],m=b(l);m!=l&&m!=null&&ca(c,k,{configurable:!0,writable:!0,value:m})}};
    ha("Symbol",function(a){if(a)return a;var b=function(f,k){this.C=f;ca(this,"description",{configurable:!0,writable:!0,value:k})};b.prototype.toString=function(){return this.C};var c="jscomp_symbol_"+(Math.random()*1E9>>>0)+"_",d=0,e=function(f){if(this instanceof e)throw new TypeError("Symbol is not a constructor");return new b(c+(f||"")+"_"+d++,f)};return e});
    var ia=function(a){var b=typeof Symbol!="undefined"&&Symbol.iterator&&a[Symbol.iterator];if(b)return b.call(a);if(typeof a.length=="number")return{next:ba(a)};throw Error(String(a)+" is not an iterable or ArrayLike");},ka=function(a){for(var b,c=[];!(b=a.next()).done;)c.push(b.value);return c},la=function(a){return a instanceof Array?a:ka(ia(a))},ma=typeof Object.assign=="function"?Object.assign:function(a,b){for(var c=1;c<arguments.length;c++){var d=arguments[c];if(d)for(var e in d)Object.prototype.hasOwnProperty.call(d,
    e)&&(a[e]=d[e])}return a};ha("Object.assign",function(a){return a||ma});var na=typeof Object.create=="function"?Object.create:function(a){var b=function(){};b.prototype=a;return new b},oa;if(typeof Object.setPrototypeOf=="function")oa=Object.setPrototypeOf;else{var qa;a:{var ra={a:!0},sa={};try{sa.__proto__=ra;qa=sa.a;break a}catch(a){}qa=!1}oa=qa?function(a,b){a.__proto__=b;if(a.__proto__!==b)throw new TypeError(a+" is not extensible");return a}:null}
    var ua=oa,va=function(a,b){a.prototype=na(b.prototype);a.prototype.constructor=a;if(ua)ua(a,b);else for(var c in b)if(c!="prototype")if(Object.defineProperties){var d=Object.getOwnPropertyDescriptor(b,c);d&&Object.defineProperty(a,c,d)}else a[c]=b[c];a.Sm=b.prototype},wa=function(){for(var a=Number(this),b=[],c=a;c<arguments.length;c++)b[c-a]=arguments[c];return b};/*
    
     Copyright The Closure Library Authors.
     SPDX-License-Identifier: Apache-2.0
    */
    var xa=this||self,ya=function(a,b,c){return a.call.apply(a.bind,arguments)},za=function(a,b,c){if(!a)throw Error();if(arguments.length>2){var d=Array.prototype.slice.call(arguments,2);return function(){var e=Array.prototype.slice.call(arguments);Array.prototype.unshift.apply(e,d);return a.apply(b,e)}}return function(){return a.apply(b,arguments)}},Aa=function(a,b,c){Aa=Function.prototype.bind&&Function.prototype.bind.toString().indexOf("native code")!=-1?ya:za;return Aa.apply(null,arguments)},Ba=
    function(a){return a};/*
     jQuery (c) 2005, 2012 jQuery Foundation, Inc. jquery.org/license.
    */
    var Ca=/\[object (Boolean|Number|String|Function|Array|Date|RegExp)\]/,Ea=function(a){if(a==null)return String(a);var b=Ca.exec(Object.prototype.toString.call(Object(a)));return b?b[1].toLowerCase():"object"},Ga=function(a,b){return Object.prototype.hasOwnProperty.call(Object(a),b)},Ha=function(a){if(!a||Ea(a)!="object"||a.nodeType||a==a.window)return!1;try{if(a.constructor&&!Ga(a,"constructor")&&!Ga(a.constructor.prototype,"isPrototypeOf"))return!1}catch(c){return!1}for(var b in a);return b===void 0||
    Ga(a,b)},g=function(a,b){var c=b||(Ea(a)=="array"?[]:{}),d;for(d in a)if(Ga(a,d)){var e=a[d];Ea(e)=="array"?(Ea(c[d])!="array"&&(c[d]=[]),c[d]=g(e,c[d])):Ha(e)?(Ha(c[d])||(c[d]={}),c[d]=g(e,c[d])):c[d]=e}return c};function Ia(){for(var a=Ja,b={},c=0;c<a.length;++c)b[a[c]]=c;return b}function La(){var a="ABCDEFGHIJKLMNOPQRSTUVWXYZ";a+=a.toLowerCase()+"0123456789-_";return a+"."}var Ja,Ma;function Na(a){Ja=Ja||La();Ma=Ma||Ia();for(var b=[],c=0;c<a.length;c+=3){var d=c+1<a.length,e=c+2<a.length,f=a.charCodeAt(c),k=d?a.charCodeAt(c+1):0,l=e?a.charCodeAt(c+2):0,m=f>>2,n=(f&3)<<4|k>>4,p=(k&15)<<2|l>>6,q=l&63;e||(q=64,d||(p=64));b.push(Ja[m],Ja[n],Ja[p],Ja[q])}return b.join("")}
    function Oa(a){function b(m){for(;d<a.length;){var n=a.charAt(d++),p=Ma[n];if(p!=null)return p;if(!/^[\s\xa0]*$/.test(n))throw Error("Unknown base64 encoding at char: "+n);}return m}Ja=Ja||La();Ma=Ma||Ia();for(var c="",d=0;;){var e=b(-1),f=b(0),k=b(64),l=b(64);if(l===64&&e===-1)return c;c+=String.fromCharCode(e<<2|f>>4);k!==64&&(c+=String.fromCharCode(f<<4&240|k>>2),l!==64&&(c+=String.fromCharCode(k<<6&192|l)))}};var Pa={};function Qa(a,b){Pa[a]=Pa[a]||[];Pa[a][b]=!0}function Ra(a){var b=Pa[a];if(!b||b.length===0)return"";for(var c=[],d=0,e=0;e<b.length;e++)e%8===0&&e>0&&(c.push(String.fromCharCode(d)),d=0),b[e]&&(d|=1<<e%8);d>0&&c.push(String.fromCharCode(d));return Na(c.join("")).replace(/\.+$/,"")}function Sa(){for(var a=[],b=Pa.fdr||[],c=0;c<b.length;c++)b[c]&&a.push(c);return a.length>0?a:void 0};function Ta(){}function Ua(a){return typeof a==="function"}function h(a){return typeof a==="string"}function Va(a){return typeof a==="number"&&!isNaN(a)}function Wa(a){return Array.isArray(a)?a:[a]}function Xa(a,b){if(a&&Array.isArray(a))for(var c=0;c<a.length;c++)if(a[c]&&b(a[c]))return a[c]}function Ya(a,b){if(!Va(a)||!Va(b)||a>b)a=0,b=2147483647;return Math.floor(Math.random()*(b-a+1)+a)}
    function Za(a,b){for(var c=new $a,d=0;d<a.length;d++)c.set(a[d],!0);for(var e=0;e<b.length;e++)if(c.get(b[e]))return!0;return!1}function ab(a,b){for(var c in a)Object.prototype.hasOwnProperty.call(a,c)&&b(c,a[c])}function bb(a){return!!a&&(Object.prototype.toString.call(a)==="[object Arguments]"||Object.prototype.hasOwnProperty.call(a,"callee"))}function cb(a){return Math.round(Number(a))||0}function db(a){return"false"===String(a).toLowerCase()?!1:!!a}
    function eb(a){var b=[];if(Array.isArray(a))for(var c=0;c<a.length;c++)b.push(String(a[c]));return b}function fb(a){return a?a.replace(/^\s+|\s+$/g,""):""}function gb(){return new Date(Date.now())}function hb(){return gb().getTime()}var $a=function(){this.prefix="gtm.";this.values={}};$a.prototype.set=function(a,b){this.values[this.prefix+a]=b};$a.prototype.get=function(a){return this.values[this.prefix+a]};function ib(a,b,c){return a&&a.hasOwnProperty(b)?a[b]:c}
    function jb(a){var b=a;return function(){if(b){var c=b;b=void 0;try{c()}catch(d){}}}}function kb(a,b){for(var c in b)b.hasOwnProperty(c)&&(a[c]=b[c])}function lb(a,b){for(var c=[],d=0;d<a.length;d++)c.push(a[d]),c.push.apply(c,b[a[d]]||[]);return c}function mb(a,b){return a.length>=b.length&&a.substring(0,b.length)===b}function nb(a,b){return a.length>=b.length&&a.substring(a.length-b.length,a.length)===b}
    function ob(a,b){for(var c={},d=c,e=a.split("."),f=0;f<e.length-1;f++)d=d[e[f]]={};d[e[e.length-1]]=b;return c}var pb=/^\w{1,9}$/;function qb(a,b){a=a||{};b=b||",";var c=[];ab(a,function(d,e){pb.test(d)&&e&&c.push(d)});return c.join(b)}function rb(a,b){function c(){e&&++d===b&&(e(),e=null,c.done=!0)}var d=0,e=a;c.done=!1;return c}function sb(a){if(a){var b=a.split(",");if(b.length===2&&b[0]===b[1])return b[0]}return a};var tb=[],ub={};function vb(a){return tb[a]===void 0?!1:tb[a]};var wb,xb=function(){if(wb===void 0){var a=null,b=xa.trustedTypes;if(b&&b.createPolicy){try{a=b.createPolicy("goog#html",{createHTML:Ba,createScript:Ba,createScriptURL:Ba})}catch(c){xa.console&&xa.console.error(c.message)}wb=a}else wb=a}return wb};var yb=function(a){this.C=a};yb.prototype.toString=function(){return this.C+""};var zb={};/*
    
     SPDX-License-Identifier: Apache-2.0
    */
    var Ab=/^\s*(?!javascript:)(?:[\w+.-]+:|[^:/?#]*(?:[/?#]|$))/i;var Bb=Array.prototype.indexOf?function(a,b){return Array.prototype.indexOf.call(a,b,void 0)}:function(a,b){if(typeof a==="string")return typeof b!=="string"||b.length!=1?-1:a.indexOf(b,0);for(var c=0;c<a.length;c++)if(c in a&&a[c]===b)return c;return-1};var Cb={},Db=function(a){this.C=a};Db.prototype.toString=function(){return this.C.toString()};function Eb(a){return a===null?"null":a===void 0?"undefined":a};var B=window,E=document,Fb=navigator,Gb=function(){var a;try{a=Fb.serviceWorker}catch(b){return}return a},Hb=E.currentScript,Ib=Hb&&Hb.src,Jb=function(a,b){var c=B[a];B[a]=c===void 0?b:c;return B[a]};function Kb(a){return(Fb.userAgent||"").indexOf(a)!==-1}
    var Lb=function(a,b){b&&(a.addEventListener?a.onload=b:a.onreadystatechange=function(){a.readyState in{loaded:1,complete:1}&&(a.onreadystatechange=null,b())})},Mb={async:1,nonce:1,onerror:1,onload:1,src:1,type:1},Nb={onload:1,src:1,width:1,height:1,style:1};function Ob(a,b,c){b&&ab(b,function(d,e){d=d.toLowerCase();c.hasOwnProperty(d)||a.setAttribute(d,e)})}
    var Pb=function(a,b,c,d,e){var f=E.createElement("script");Ob(f,d,Mb);f.type="text/javascript";f.async=d&&d.async===!1?!1:!0;var k,l=Eb(a),m=xb(),n=m?m.createScriptURL(l):l;k=new yb(n,zb);f.src=k instanceof yb&&k.constructor===yb?k.C:"type_error:TrustedResourceUrl";var p,q,r,t=(r=(q=(f.ownerDocument&&f.ownerDocument.defaultView||window).document).querySelector)==null?void 0:r.call(q,"script[nonce]");(p=t?t.nonce||t.getAttribute("nonce")||"":"")&&f.setAttribute("nonce",p);Lb(f,b);c&&(f.onerror=c);
    if(e)e.appendChild(f);else{var u=E.getElementsByTagName("script")[0]||E.body||E.head;u.parentNode.insertBefore(f,u)}return f},Qb=function(){if(Ib){var a=Ib.toLowerCase();if(a.indexOf("https://")===0)return 2;if(a.indexOf("http://")===0)return 3}return 1},Rb=function(a,b,c,d,e){var f;f=f===void 0?!0:f;var k=e,l=!1;k||(k=E.createElement("iframe"),l=!0);Ob(k,c,Nb);d&&ab(d,function(n,p){k.dataset[n]=p});f&&(k.height="0",k.width="0",k.style.display="none",k.style.visibility="hidden");a!==void 0&&(k.src=
    a);if(l){var m=E.body&&E.body.lastChild||E.body||E.head;m.parentNode.insertBefore(k,m)}Lb(k,b);return k},Sb=function(a,b,c,d){var e=new Image(1,1);Ob(e,d,{});e.onload=function(){e.onload=null;b&&b()};e.onerror=function(){e.onerror=null;c&&c()};e.src=a;return e},Tb=function(a,b,c,d){a.addEventListener?a.addEventListener(b,c,!!d):a.attachEvent&&a.attachEvent("on"+b,c)},Ub=function(a,b,c){a.removeEventListener?a.removeEventListener(b,c,!1):a.detachEvent&&a.detachEvent("on"+b,c)},H=function(a){B.setTimeout(a,
    0)},Vb=function(a,b){return a&&b&&a.attributes&&a.attributes[b]?a.attributes[b].value:null},Wb=function(a){function b(e){e&&e!=" "&&(e=e.replace(/^[\s\xa0]+|[\s\xa0]+$/g,""));e&&e!=" "&&(e=e.replace(/^[\s\xa0]+|[\s\xa0]+$/g,""));e&&(e=e.replace(/(\xa0+|\s{2,}|\n|\r\t)/g," "));return e}var c=b(a.innerText||a.textContent||"");if(vb(9)){var d=b(a.textContent||"");Qa("TAGGING",26);d!==c&&Qa("TAGGING",25)}return c},Xb=function(a){var b=E.createElement("div"),c=b,d,e=Eb("A<div>"+a+"</div>"),f=xb(),k=f?
    f.createHTML(e):e;d=new Db(k,Cb);if(c.nodeType===1){var l=c.tagName;if(l==="SCRIPT"||l==="STYLE")throw Error("");}c.innerHTML=d instanceof Db&&d.constructor===Db?d.C:"type_error:SafeHtml";b=b.lastChild;for(var m=[];b.firstChild;)m.push(b.removeChild(b.firstChild));return m},Yb=function(a){var b;try{b=Fb.sendBeacon&&Fb.sendBeacon(a)}catch(c){Qa("TAGGING",15)}b||Sb(a)},Zb=function(a,b){try{return Fb.sendBeacon(a,b)}catch(c){Qa("TAGGING",15)}return!1},$b={cache:"no-store",credentials:"include",keepalive:!0,
    method:"POST",mode:"no-cors",redirect:"follow"},bc=function(a,b,c){if(ac()){var d=Object.assign({},$b);b&&(d.body=b);c&&(c.attributionReporting&&(d.attributionReporting=c.attributionReporting),c.browsingTopics&&(d.browsingTopics=c.browsingTopics));try{var e=B.fetch(a,d);e&&e.catch(Ta);return!0}catch(f){}}if(c&&c.noFallback)return!1;if(b)return Zb(a,b);Yb(a);return!0},ac=function(){return typeof B.fetch==="function"},cc=function(a,b){var c=a[b];c&&typeof c.animVal==="string"&&(c=c.animVal);return c},
    dc=function(){var a=B.performance;if(a&&Ua(a.now))return a.now()},ec=function(){return B.performance||void 0};var fc=function(a){this.message=a};function gc(a){var b="0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-_"[a];return b===void 0?new fc("Value "+a+" can not be encoded in web-safe base64 dictionary."):b};function hc(a){switch(a){case 1:return"1";case 2:case 4:return"0";default:return"-"}};var ic=/^[1-9a-zA-Z_-][1-9a-c][1-9a-v]\d$/;function jc(a,b){for(var c="",d=!0;a>7;){var e=a&31;a>>=5;d?d=!1:e|=32;c=""+gc(e)+c}a<<=2;d||(a|=32);return c=""+gc(a|b)+c};var kc=function(){function a(b){return{toString:function(){return b}}}return{Si:a("consent"),yg:a("convert_case_to"),zg:a("convert_false_to"),Ag:a("convert_null_to"),Bg:a("convert_true_to"),Cg:a("convert_undefined_to"),dm:a("debug_mode_metadata"),ma:a("function"),yf:a("instance_name"),Oj:a("live_only"),Pj:a("malware_disabled"),Qj:a("metadata"),Tj:a("original_activity_id"),qm:a("original_vendor_template_id"),om:a("once_on_load"),Sj:a("once_per_event"),Sh:a("once_per_load"),vm:a("priority_override"),
    wm:a("respected_consent_types"),bi:a("setup_tags"),sd:a("tag_id"),ji:a("teardown_tags")}}();var Gc;var Hc=[],Ic=[],Jc=[],Kc=[],Lc=[],Mc={},Nc,Oc;function Pc(a){Oc=Oc||a}
    function Qc(a){}var Rc,Sc=[],Tc=[];function Uc(a,b,c){try{return Nc(Vc(a,b,c))}catch(d){JSON.stringify(a)}return 2}
    function Wc(a){var b=a[kc.ma];if(!b)throw Error("Error: No function name given for function call.");return!!Mc[b]}
    var Vc=function(a,b,c){c=c||[];var d={},e;for(e in a)a.hasOwnProperty(e)&&(d[e]=Xc(a[e],b,c));return d},Xc=function(a,b,c){if(Array.isArray(a)){var d;switch(a[0]){case "function_id":return a[1];case "list":d=[];for(var e=1;e<a.length;e++)d.push(Xc(a[e],b,c));return d;case "macro":var f=a[1];if(c[f])return;var k=Hc[f];if(!k||b.isBlocked(k))return;c[f]=!0;var l=String(k[kc.yf]);try{var m=Vc(k,b,c);m.vtp_gtmEventId=b.id;b.priorityId&&(m.vtp_gtmPriorityId=b.priorityId);d=Yc(m,{event:b,index:f,type:2,
    name:l});Rc&&(d=Rc.pk(d,m))}catch(y){b.logMacroError&&b.logMacroError(y,Number(f),l),d=!1}c[f]=!1;return d;case "map":d={};for(var n=1;n<a.length;n+=2)d[Xc(a[n],b,c)]=Xc(a[n+1],b,c);return d;case "template":d=[];for(var p=!1,q=1;q<a.length;q++){var r=Xc(a[q],b,c);Oc&&(p=p||Oc.Zk(r));d.push(r)}return Oc&&p?Oc.sk(d):d.join("");case "escape":d=Xc(a[1],b,c);if(Oc&&Array.isArray(a[1])&&a[1][0]==="macro"&&Oc.al(a))return Oc.zl(d);d=String(d);for(var t=2;t<a.length;t++)lc[a[t]]&&(d=lc[a[t]](d));return d;
    case "tag":var u=a[1];if(!Kc[u])throw Error("Unable to resolve tag reference "+u+".");return{ri:a[2],index:u};case "zb":var v={arg0:a[2],arg1:a[3],ignore_case:a[5]};v[kc.ma]=a[1];var w=Uc(v,b,c),x=!!a[4];return x||w!==2?x!==(w===1):null;default:throw Error("Attempting to expand unknown Value type: "+a[0]+".");}}return a},Yc=function(a,b){var c=a[kc.ma],d=b&&b.event;if(!c)throw Error("Error: No function name given for function call.");var e=Mc[c],f=b&&b.type===2&&(d==null?void 0:d.reportMacroDiscrepancy)&&
    e&&Sc.indexOf(c)!==-1,k={},l;for(l in a)a.hasOwnProperty(l)&&mb(l,"vtp_")&&e&&(k[l]=a[l]);e&&d&&d.cachedModelValues&&(k.vtp_gtmCachedValues=d.cachedModelValues);if(b){if(b.name==null){var m;a:{var n=b.type,p=b.index;if(p==null)m="";else{var q;switch(n){case 2:q=Hc[p];break;case 1:q=Kc[p];break;default:m="";break a}var r=q&&q[kc.yf];m=r?String(r):""}}b.name=m}e&&(k.vtp_gtmEntityIndex=b.index,k.vtp_gtmEntityName=b.name)}var t,u,v;if(f&&Tc.indexOf(c)===-1){Tc.push(c);var w=hb();t=e(k);var x=hb()-w,y=
    hb();u=Gc(c,b);v=x-(hb()-y)}else if(e&&(t=e(k)),!e||f)u=Gc(c,b);if(f&&d){d.reportMacroDiscrepancy(d.id,c,void 0,!0);var z;a:{var A=t;if(A==void 0||Array.isArray(A)||Ha(A))z=!0;else{switch(typeof A){case "boolean":case "number":case "string":case "function":z=!0;break a}z=!1}}z?(Array.isArray(t)?Array.isArray(u):Ha(t)?Ha(u):typeof t==="function"?typeof u==="function":t===u)||d.reportMacroDiscrepancy(d.id,c):t!==u&&d.reportMacroDiscrepancy(d.id,c);v!==void 0&&d.reportMacroDiscrepancy(d.id,c,v)}return e?
    t:u};function Zc(a){function b(r){for(var t=0;t<r.length;t++)d[r[t]]=!0}for(var c=[],d=[],e=$c(a),f=0;f<Ic.length;f++){var k=Ic[f],l=ad(k,e);if(l){for(var m=k.add||[],n=0;n<m.length;n++)c[m[n]]=!0;b(k.block||[])}else l===null&&b(k.block||[]);}for(var p=[],q=0;q<Kc.length;q++)c[q]&&!d[q]&&(p[q]=!0);return p}
    function ad(a,b){for(var c=a["if"]||[],d=0;d<c.length;d++){var e=b(c[d]);if(e===0)return!1;if(e===2)return null}for(var f=a.unless||[],k=0;k<f.length;k++){var l=b(f[k]);if(l===2)return null;if(l===1)return!1}return!0}function $c(a){var b=[];return function(c){b[c]===void 0&&(b[c]=Uc(Jc[c],a));return b[c]}};var bd={pk:function(a,b){b[kc.yg]&&typeof a==="string"&&(a=b[kc.yg]===1?a.toLowerCase():a.toUpperCase());b.hasOwnProperty(kc.Ag)&&a===null&&(a=b[kc.Ag]);b.hasOwnProperty(kc.Cg)&&a===void 0&&(a=b[kc.Cg]);b.hasOwnProperty(kc.Bg)&&a===!0&&(a=b[kc.Bg]);b.hasOwnProperty(kc.zg)&&a===!1&&(a=b[kc.zg]);return a}};var hd={},id=(hd.uaa=!0,hd.uab=!0,hd.uafvl=!0,hd.uamb=!0,hd.uam=!0,hd.uap=!0,hd.uapv=!0,hd.uaw=!0,hd);var rd=["matches","webkitMatchesSelector","mozMatchesSelector","msMatchesSelector","oMatchesSelector"],sd=new $a;function zd(a){return Ad?E.querySelectorAll(a):null}
    function Bd(a,b){if(!Ad)return null;if(Element.prototype.closest)try{return a.closest(b)}catch(e){return null}var c=Element.prototype.matches||Element.prototype.webkitMatchesSelector||Element.prototype.mozMatchesSelector||Element.prototype.msMatchesSelector||Element.prototype.oMatchesSelector,d=a;if(!E.documentElement.contains(d))return null;do{try{if(c.call(d,b))return d}catch(e){break}d=d.parentElement||d.parentNode}while(d!==null&&d.nodeType===1);return null}var Cd=!1;
    if(E.querySelectorAll)try{var Dd=E.querySelectorAll(":root");Dd&&Dd.length==1&&Dd[0]==E.documentElement&&(Cd=!0)}catch(a){}var Ad=Cd;var Ed=/^[0-9A-Fa-f]{64}$/;function Fd(a){try{return(new TextEncoder).encode(a)}catch(e){for(var b=[],c=0;c<a.length;c++){var d=a.charCodeAt(c);d<128?b.push(d):d<2048?b.push(192|d>>6,128|d&63):d<55296||d>=57344?b.push(224|d>>12,128|d>>6&63,128|d&63):(d=65536+((d&1023)<<10|a.charCodeAt(++c)&1023),b.push(240|d>>18,128|d>>12&63,128|d>>6&63,128|d&63))}return new Uint8Array(b)}}
    function Gd(a){if(a===""||a==="e0")return Promise.resolve(a);var b;if((b=B.crypto)==null?0:b.subtle){if(Ed.test(a))return Promise.resolve(a);try{var c=Fd(a);return B.crypto.subtle.digest("SHA-256",c).then(function(d){var e=Array.from(new Uint8Array(d)).map(function(f){return String.fromCharCode(f)}).join("");return B.btoa(e).replace(/\+/g,"-").replace(/\//g,"_").replace(/=+$/,"")}).catch(function(){return"e2"})}catch(d){return Promise.resolve("e2")}}else return Promise.resolve("e1")};function K(a){Qa("GTM",a)};var L={g:{na:"ad_personalization",N:"ad_storage",M:"ad_user_data",P:"analytics_storage",Od:"region",Jb:"consent_updated",sg:"wait_for_update",Vi:"app_remove",Wi:"app_store_refund",Xi:"app_store_subscription_cancel",Yi:"app_store_subscription_convert",Zi:"app_store_subscription_renew",aj:"consent_update",Fg:"add_payment_info",Gg:"add_shipping_info",hc:"add_to_cart",ic:"remove_from_cart",Hg:"view_cart",Kb:"begin_checkout",jc:"select_item",Za:"view_item_list",sb:"select_promotion",ab:"view_promotion",
    Fa:"purchase",kc:"refund",Ja:"view_item",Ig:"add_to_wishlist",bj:"exception",cj:"first_open",dj:"first_visit",X:"gtag.config",Ma:"gtag.get",ej:"in_app_purchase",Lb:"page_view",fj:"screen_view",gj:"session_start",ij:"timing_complete",jj:"track_social",Mc:"user_engagement",kj:"user_id_update",cb:"gclgb",Na:"gclid",Jg:"gclgs",Kg:"gclst",ba:"ads_data_redaction",Lg:"gad_source",Nc:"gclid_url",Mg:"gclsrc",Xe:"gbraid",Pd:"wbraid",ia:"allow_ad_personalization_signals",Ye:"allow_custom_scripts",Oc:"allow_direct_google_requests",
    Ze:"allow_display_features",Qd:"allow_enhanced_conversions",eb:"allow_google_signals",Aa:"allow_interest_groups",lj:"app_id",mj:"app_installer_id",nj:"app_name",oj:"app_version",tb:"auid",pj:"auto_detection_enabled",Mb:"aw_remarketing",af:"aw_remarketing_only",Rd:"discount",Sd:"aw_feed_country",Td:"aw_feed_language",Z:"items",Ud:"aw_merchant_id",Ng:"aw_basket_type",Pc:"campaign_content",Qc:"campaign_id",Rc:"campaign_medium",Sc:"campaign_name",Tc:"campaign",Uc:"campaign_source",Vc:"campaign_term",
    fb:"client_id",Og:"rnd",bf:"consent_update_type",qj:"content_group",rj:"content_type",Sa:"conversion_cookie_prefix",Wc:"conversion_id",oa:"conversion_linker",Pg:"conversion_linker_disabled",Nb:"conversion_api",Vd:"cookie_deprecation",Oa:"cookie_domain",Pa:"cookie_expires",Ta:"cookie_flags",mc:"cookie_name",ub:"cookie_path",Ka:"cookie_prefix",nc:"cookie_update",oc:"country",wa:"currency",Wd:"customer_lifetime_value",Xc:"custom_map",cf:"gcldc",Yc:"dclid",sj:"debug_mode",ja:"developer_id",tj:"disable_merchant_reported_purchases",
    Zc:"dc_custom_params",uj:"dc_natural_search",Qg:"dynamic_event_settings",Rg:"affiliation",Xd:"checkout_option",df:"checkout_step",Sg:"coupon",bd:"item_list_name",ef:"list_name",vj:"promotions",dd:"shipping",ff:"tax",Yd:"engagement_time_msec",Zd:"enhanced_client_id",ae:"enhanced_conversions",Tg:"enhanced_conversions_automatic_settings",be:"estimated_delivery_date",hf:"euid_logged_in_state",ed:"event_callback",wj:"event_category",ib:"event_developer_id_string",xj:"event_label",qc:"event",ce:"event_settings",
    de:"event_timeout",yj:"description",zj:"fatal",Aj:"experiments",jf:"firebase_id",rc:"first_party_collection",ee:"_x_20",jb:"_x_19",Ug:"fledge_drop_reason",Vg:"fledge",Wg:"flight_error_code",Xg:"flight_error_message",Yg:"fl_activity_category",Zg:"fl_activity_group",kf:"fl_advertiser_id",ah:"fl_ar_dedupe",lf:"match_id",bh:"fl_random_number",eh:"tran",fh:"u",fe:"gac_gclid",sc:"gac_wbraid",gh:"gac_wbraid_multiple_conversions",hh:"ga_restrict_domain",ih:"ga_temp_client_id",uc:"gdpr_applies",jh:"geo_granularity",
    vb:"value_callback",kb:"value_key",vc:"_google_ng",Ob:"google_signals",kh:"google_tld",he:"groups",lh:"gsa_experiment_id",mh:"gtm_up",wb:"iframe_state",fd:"ignore_referrer",nf:"internal_traffic_results",xb:"is_legacy_converted",yb:"is_legacy_loaded",ie:"is_passthrough",wc:"_lps",La:"language",je:"legacy_developer_id_string",qa:"linker",xc:"accept_incoming",nb:"decorate_forms",R:"domains",zb:"url_position",nh:"method",Bj:"name",gd:"new_customer",oh:"non_interaction",Cj:"optimize_id",Dj:"page_hostname",
    hd:"page_path",Ba:"page_referrer",Ab:"page_title",qh:"passengers",rh:"phone_conversion_callback",Ej:"phone_conversion_country_code",sh:"phone_conversion_css_class",Fj:"phone_conversion_ids",th:"phone_conversion_number",uh:"phone_conversion_options",pf:"_protected_audience_enabled",jd:"quantity",ke:"redact_device_info",qf:"referral_exclusion_definition",Pb:"restricted_data_processing",Gj:"retoken",Hj:"sample_rate",rf:"screen_name",Bb:"screen_resolution",Ij:"search_term",Ga:"send_page_view",Qb:"send_to",
    yc:"server_container_url",kd:"session_duration",me:"session_engaged",tf:"session_engaged_time",ob:"session_id",ne:"session_number",oe:"_shared_user_id",ld:"delivery_postal_code",gm:"temporary_client_id",uf:"topmost_url",Jj:"tracking_id",vf:"traffic_type",xa:"transaction_id",Cb:"transport_url",vh:"trip_type",Rb:"update",Qa:"url_passthrough",pe:"_user_agent_architecture",qe:"_user_agent_bitness",se:"_user_agent_full_version_list",te:"_user_agent_mobile",ue:"_user_agent_model",ve:"_user_agent_platform",
    we:"_user_agent_platform_version",xe:"_user_agent_wow64",Ca:"user_data",wh:"user_data_auto_latency",xh:"user_data_auto_meta",yh:"user_data_auto_multi",zh:"user_data_auto_selectors",Ah:"user_data_auto_status",md:"user_data_mode",ye:"user_data_settings",ya:"user_id",Ua:"user_properties",Bh:"_user_region",ze:"us_privacy_string",ka:"value",Ch:"wbraid_multiple_conversions",Kh:"_host_name",Lh:"_in_page_command",Mh:"_is_passthrough_cid",pb:"non_personalized_ads",rd:"_sst_parameters",hb:"conversion_label",
    ra:"page_location",lb:"global_developer_id_string",zc:"tc_privacy_string"}},de={},ee=Object.freeze((de[L.g.ia]=1,de[L.g.Ze]=1,de[L.g.Qd]=1,de[L.g.eb]=1,de[L.g.Z]=1,de[L.g.Oa]=1,de[L.g.Pa]=1,de[L.g.Ta]=1,de[L.g.mc]=1,de[L.g.ub]=1,de[L.g.Ka]=1,de[L.g.nc]=1,de[L.g.Xc]=1,de[L.g.ja]=1,de[L.g.Qg]=1,de[L.g.ed]=1,de[L.g.ce]=1,de[L.g.de]=1,de[L.g.rc]=1,de[L.g.hh]=1,de[L.g.Ob]=1,de[L.g.kh]=1,de[L.g.he]=1,de[L.g.nf]=1,de[L.g.xb]=1,de[L.g.yb]=1,de[L.g.qa]=1,de[L.g.qf]=1,de[L.g.Pb]=1,de[L.g.Ga]=1,de[L.g.Qb]=1,
    de[L.g.yc]=1,de[L.g.kd]=1,de[L.g.tf]=1,de[L.g.ld]=1,de[L.g.Cb]=1,de[L.g.Rb]=1,de[L.g.ye]=1,de[L.g.Ua]=1,de[L.g.rd]=1,de));Object.freeze([L.g.ra,L.g.Ba,L.g.Ab,L.g.La,L.g.rf,L.g.ya,L.g.jf,L.g.qj]);
    var fe={},ge=Object.freeze((fe[L.g.Vi]=1,fe[L.g.Wi]=1,fe[L.g.Xi]=1,fe[L.g.Yi]=1,fe[L.g.Zi]=1,fe[L.g.cj]=1,fe[L.g.dj]=1,fe[L.g.ej]=1,fe[L.g.gj]=1,fe[L.g.Mc]=1,fe)),he={},ie=Object.freeze((he[L.g.Fg]=1,he[L.g.Gg]=1,he[L.g.hc]=1,he[L.g.ic]=1,he[L.g.Hg]=1,he[L.g.Kb]=1,he[L.g.jc]=1,he[L.g.Za]=1,he[L.g.sb]=1,he[L.g.ab]=1,he[L.g.Fa]=1,he[L.g.kc]=1,he[L.g.Ja]=1,he[L.g.Ig]=1,he)),je=Object.freeze([L.g.ia,L.g.Oc,L.g.eb,L.g.nc,L.g.rc,L.g.fd,L.g.Ga,L.g.Rb]),ke=Object.freeze([].concat(la(je))),le=Object.freeze([L.g.Pa,
    L.g.de,L.g.kd,L.g.tf,L.g.Yd]),me=Object.freeze([].concat(la(le))),ne={},oe=(ne[L.g.N]="1",ne[L.g.P]="2",ne[L.g.M]="3",ne[L.g.na]="4",ne),pe={},qe=Object.freeze((pe[L.g.ia]=1,pe[L.g.Oc]=1,pe[L.g.Qd]=1,pe[L.g.Aa]=1,pe[L.g.Mb]=1,pe[L.g.af]=1,pe[L.g.Rd]=1,pe[L.g.Sd]=1,pe[L.g.Td]=1,pe[L.g.Z]=1,pe[L.g.Ud]=1,pe[L.g.Sa]=1,pe[L.g.oa]=1,pe[L.g.Oa]=1,pe[L.g.Pa]=1,pe[L.g.Ta]=1,pe[L.g.Ka]=1,pe[L.g.wa]=1,pe[L.g.Wd]=1,pe[L.g.ja]=1,pe[L.g.tj]=1,pe[L.g.ae]=1,pe[L.g.be]=1,pe[L.g.jf]=1,pe[L.g.rc]=1,pe[L.g.xb]=1,pe[L.g.yb]=
    1,pe[L.g.La]=1,pe[L.g.gd]=1,pe[L.g.ra]=1,pe[L.g.Ba]=1,pe[L.g.rh]=1,pe[L.g.sh]=1,pe[L.g.th]=1,pe[L.g.uh]=1,pe[L.g.Pb]=1,pe[L.g.Ga]=1,pe[L.g.Qb]=1,pe[L.g.yc]=1,pe[L.g.ld]=1,pe[L.g.xa]=1,pe[L.g.Cb]=1,pe[L.g.Rb]=1,pe[L.g.Qa]=1,pe[L.g.Ca]=1,pe[L.g.ya]=1,pe[L.g.ka]=1,pe)),re={},se=Object.freeze((re.search="s",re.youtube="y",re.playstore="p",re.shopping="h",re.ads="a",re.maps="m",re));Object.freeze(L.g);var te={},ue=B.google_tag_manager=B.google_tag_manager||{};te.zf="47t0";te.qd=Number("0")||0;te.Ya="dataLayer";te.bm="ChAI8MGntQYQhauL4s3JhYxaEhkAx8XYJSlkTOgj2lXFWG8RJaMlHilIftY0GgIDaw\x3d\x3d";var ve={__cl:1,__ecl:1,__ehl:1,__evl:1,__fal:1,__fil:1,__fsl:1,__hl:1,__jel:1,__lcl:1,__sdl:1,__tl:1,__ytl:1},we={__paused:1,__tg:1},xe;for(xe in ve)ve.hasOwnProperty(xe)&&(we[xe]=1);var ye=db(""),ze,Ae=!1;Ae=!0;ze=Ae;
    var Be,Ce=!1;Be=Ce;var De,Ee=!1;De=Ee;te.Kc="www.googletagmanager.com";var Fe=""+te.Kc+(ze?"/gtag/js":"/gtm.js"),Ge=null,He=null,Ie={},Je={};function Ke(){var a=ue.sequence||1;ue.sequence=a+1;return a}te.Ti="";var Le="";te.Ge=Le;var Me=new function(){this.C="";this.F=this.D=!1;this.Ha=this.O=this.T=this.J=""};function Ne(){var a=Me.J.length;return Me.J[a-1]==="/"?Me.J.substring(0,a-1):Me.J}function Oe(a){for(var b={},c=ia(a.split("|")),d=c.next();!d.done;d=c.next())b[d.value]=!0;return b}var Pe=new $a,Qe={},Re={},Ue={name:te.Ya,set:function(a,b){g(ob(a,b),Qe);Se()},get:function(a){return Te(a,2)},reset:function(){Pe=new $a;Qe={};Se()}};function Te(a,b){return b!=2?Pe.get(a):Ve(a)}function Ve(a){var b,c=a.split(".");b=b||[];for(var d=Qe,e=0;e<c.length;e++){if(d===null)return!1;if(d===void 0)break;d=d[c[e]];if(b.indexOf(d)!==-1)return}return d}function We(a,b){Re.hasOwnProperty(a)||(Pe.set(a,b),g(ob(a,b),Qe),Se())}
    function Se(a){ab(Re,function(b,c){Pe.set(b,c);g(ob(b),Qe);g(ob(b,c),Qe);a&&delete Re[b]})}function Xe(a,b){var c,d=(b===void 0?2:b)!==1?Ve(a):Pe.get(a);Ea(d)==="array"||Ea(d)==="object"?c=g(d):c=d;return c};var af=/:[0-9]+$/,bf=/^\d+\.fls\.doubleclick\.net$/;function cf(a,b,c){for(var d=ia(a.split("&")),e=d.next();!e.done;e=d.next()){var f=ia(e.value.split("=")),k=f.next().value,l=ka(f);if(decodeURIComponent(k.replace(/\+/g," "))===b){var m=l.join("=");return c?m:decodeURIComponent(m.replace(/\+/g," "))}}}
    function df(a,b,c,d,e){b&&(b=String(b).toLowerCase());if(b==="protocol"||b==="port")a.protocol=ef(a.protocol)||ef(B.location.protocol);b==="port"?a.port=String(Number(a.hostname?a.port:B.location.port)||(a.protocol==="http"?80:a.protocol==="https"?443:"")):b==="host"&&(a.hostname=(a.hostname||B.location.hostname).replace(af,"").toLowerCase());return ff(a,b,c,d,e)}
    function ff(a,b,c,d,e){var f,k=ef(a.protocol);b&&(b=String(b).toLowerCase());switch(b){case "url_no_fragment":f=gf(a);break;case "protocol":f=k;break;case "host":f=a.hostname.replace(af,"").toLowerCase();if(c){var l=/^www\d*\./.exec(f);l&&l[0]&&(f=f.substring(l[0].length))}break;case "port":f=String(Number(a.port)||(k==="http"?80:k==="https"?443:""));break;case "path":a.pathname||a.hostname||Qa("TAGGING",1);f=a.pathname.substring(0,1)==="/"?a.pathname:"/"+a.pathname;var m=f.split("/");(d||[]).indexOf(m[m.length-
    1])>=0&&(m[m.length-1]="");f=m.join("/");break;case "query":f=a.search.replace("?","");e&&(f=cf(f,e));break;case "extension":var n=a.pathname.split(".");f=n.length>1?n[n.length-1]:"";f=f.split("/")[0];break;case "fragment":f=a.hash.replace("#","");break;default:f=a&&a.href}return f}function ef(a){return a?a.replace(":","").toLowerCase():""}function gf(a){var b="";if(a&&a.href){var c=a.href.indexOf("#");b=c<0?a.href:a.href.substring(0,c)}return b}var hf={},jf=0;
    function kf(a){var b=hf[a];if(!b){var c=E.createElement("a");a&&(c.href=a);var d=c.pathname;d[0]!=="/"&&(a||Qa("TAGGING",1),d="/"+d);var e=c.hostname.replace(af,"");b={href:c.href,protocol:c.protocol,host:c.host,hostname:e,pathname:d,search:c.search,hash:c.hash,port:c.port};jf<5&&(hf[a]=b,jf++)}return b}
    function lf(a){function b(n){var p=n.split("=")[0];return d.indexOf(p)<0?n:p+"=0"}function c(n){return n.split("&").map(b).filter(function(p){return p!==void 0}).join("&")}var d="gclid dclid gbraid wbraid gclaw gcldc gclha gclgf gclgb _gl".split(" "),e=kf(a),f=a.split(/[?#]/)[0],k=e.search,l=e.hash;k[0]==="?"&&(k=k.substring(1));l[0]==="#"&&(l=l.substring(1));k=c(k);l=c(l);k!==""&&(k="?"+k);l!==""&&(l="#"+l);var m=""+f+k+l;m[m.length-1]==="/"&&(m=m.substring(0,m.length-1));return m}
    function mf(a){var b=kf(B.location.href),c=df(b,"host",!1);if(c&&c.match(bf)){var d=df(b,"path");if(d){var e=d.split(a+"=");if(e.length>1)return e[1].split(";")[0].split("?")[0]}}};var nf={"https://www.google.com":"/g","https://www.googleadservices.com":"/as","https://pagead2.googlesyndication.com":"/gs"};function of(a,b){if(a){var c=""+a;c.indexOf("http://")!==0&&c.indexOf("https://")!==0&&(c="https://"+c);c[c.length-1]==="/"&&(c=c.substring(0,c.length-1));return kf(""+c+b).href}}function pf(a,b){if(Me.D||Be)return of(a,b)}function qf(){return!!te.Ge&&te.Ge.split("@@").join("")!=="SGTM_TOKEN"}
    function rf(a){for(var b=ia([L.g.yc,L.g.Cb]),c=b.next();!c.done;c=b.next()){var d=M(a,c.value);if(d)return d}}function sf(a,b){return Me.D?""+Ne()+(b?nf[a]||"":""):a};function tf(a){var b=String(a[kc.ma]||"").replace(/_/g,"");return mb(b,"cvt")?"cvt":b}var uf=B.location.search.indexOf("?gtm_latency=")>=0||B.location.search.indexOf("&gtm_latency=")>=0;var vf={sampleRate:"0.005000",Pi:"",Zl:"0.005"},wf=Math.random(),xf;if(!(xf=uf)){var yf=vf.sampleRate;xf=wf<Number(yf)}var zf=xf,Af=(Ib==null?void 0:Ib.includes("gtm_debug=d"))||uf||wf>=1-Number(vf.Zl);var Bf=/gtag[.\/]js/,Cf=/gtm[.\/]js/,Df=!1;
    function Ef(a){if((a.scriptContainerId||"").indexOf("GTM-")>=0){var b;a:{if(a.scriptSource){for(var c=Me.F,d=kf(a.scriptSource),e=c?d.pathname:""+d.hostname+d.pathname,f=E.scripts,k="",l=0;l<f.length;++l){var m=f[l];if(!(m.innerHTML.length===0||!c&&m.innerHTML.indexOf(a.scriptContainerId||"SHOULD_NOT_BE_SET")<0||m.innerHTML.indexOf(e)<0)){if(m.innerHTML.indexOf("(function(w,d,s,l,i)")>=0){b=String(l);break a}k=String(l)}}if(k){b=k;break a}}b=void 0}var n=b;if(n)return Df=!0,n}var p=[].slice.call(document.scripts);
    return a.scriptElement?String(p.indexOf(a.scriptElement)):"-1"}function Ff(a){if(Df)return"1";var b=a.scriptSource;if(b){if(Bf.test(b))return"3";if(Cf.test(b))return"2"}return"0"}function Gf(a,b){if(a==="")return b;var c=Number(a);return isNaN(c)?b:c};var Hf=[];function If(a){switch(a){case 0:return 0;case 41:return 1;case 42:return 2;case 54:return 3;case 60:return 6;case 71:return 4;case 79:return 5;case 80:return 9;case 82:return 7;case 83:return 8}}function N(a){Hf[a]=!0;var b=If(a);b!==void 0&&(tb[b]=!0)}
    N(29);N(25);N(26);N(27);N(28);
    N(43);N(64);N(51);
    N(61);N(32);N(15);N(88);N(14);N(93);N(87);N(55);N(72);
    N(7);N(44);N(4);
    N(68);N(84);N(59);
    N(58);N(70);
    N(97);
    N(94);N(71);N(5);N(79);ub[1]=Gf('1',6E4);ub[3]=Gf('10',1);ub[2]=Gf('',50);
    N(22);
    N(12);N(57);N(85);
    
    
    N(45);N(67);
    N(40);N(73);N(69);
    N(80);
    function P(a){return!!Hf[a]}function Mf(a){var b=Nf();b.pending||(b.pending=[]);Xa(b.pending,function(c){return c.target.ctid===a.ctid&&c.target.isDestination===a.isDestination})||b.pending.push({target:a,onLoad:void 0})}var Of=function(){this.container={};this.destination={};this.canonical={};this.pending=[];this.siloed=[]};function Nf(){var a=Jb("google_tag_data",{}),b=a.tidr;b||(b=new Of,a.tidr=b);return b};var Pf={},Qf=!1,Rf={ctid:"",canonicalContainerId:"",Ai:"",Bi:""};Pf.od=db("");function Sf(){var a=Tf();return Qf?a.map(Uf):a}function Vf(){var a=Wf();return Qf?a.map(Uf):a}function Xf(){return Yf(Rf.ctid)}function Zf(){return Yf(Rf.canonicalContainerId||"_"+Rf.ctid)}function Tf(){return Rf.Ai?Rf.Ai.split("|"):[Rf.ctid]}function Wf(){return Rf.Bi?Rf.Bi.split("|"):[]}
    function bg(){var a=cg(dg()),b=a&&a.parent;if(b)return cg(b)}function cg(a){var b=Nf();return a.isDestination?b.destination[a.ctid]:b.container[a.ctid]}function Yf(a){return Qf?Uf(a):a}function Uf(a){return"siloed_"+a}function eg(a){return Qf?fg(a):a}function fg(a){a=String(a);return mb(a,"siloed_")?a.substring(7):a}
    function gg(){var a=!1;if(a){var b=Nf();if(b.siloed){for(var c=[],d=Tf().map(Uf),e=Wf().map(Uf),f={},k=0;k<b.siloed.length;f={Je:void 0},k++)f.Je=b.siloed[k],!Qf&&Xa(f.Je.isDestination?e:d,function(l){return function(m){return m===l.Je.ctid}}(f))?Qf=!0:c.push(f.Je);b.siloed=c}}}
    function hg(){var a=Nf();if(a.pending){for(var b,c=[],d=!1,e=Sf(),f=Vf(),k={},l=0;l<a.pending.length;k={Kd:void 0},l++)k.Kd=a.pending[l],Xa(k.Kd.target.isDestination?f:e,function(m){return function(n){return n===m.Kd.target.ctid}}(k))?d||(b=k.Kd.onLoad,d=!0):c.push(k.Kd);a.pending=c;if(b)try{b(Zf())}catch(m){}}}
    function ig(){for(var a=Rf.ctid,b=Sf(),c=Vf(),d=function(n,p){var q={canonicalContainerId:Rf.canonicalContainerId,scriptContainerId:a,state:2,containers:b.slice(),destinations:c.slice()};Hb&&(q.scriptElement=Hb);Ib&&(q.scriptSource=Ib);P(45)&&bg()===void 0&&(q.htmlLoadOrder=Ef(q),q.loadScriptType=Ff(q));var r=p?e.destination:e.container,t=r[n];t?(p&&t.state===0&&K(93),Object.assign(t,q)):r[n]=q},e=Nf(),f=ia(b),k=f.next();!k.done;k=f.next())d(k.value,!1);for(var l=ia(c),m=l.next();!m.done;m=l.next())d(m.value,
    !0);e.canonical[Zf()]={};hg()}function dg(){return{ctid:Xf(),isDestination:Pf.od}}function jg(a){var b=Nf();(b.siloed=b.siloed||[]).push(a)}function kg(){var a=Nf().container,b;for(b in a)if(a.hasOwnProperty(b)&&a[b].state===1)return!0;return!1}function lg(){var a={};ab(Nf().destination,function(b,c){c.state===0&&(a[fg(b)]=c)});return a}function mg(a){return!!(a&&a.parent&&a.context&&a.context.source===1&&a.parent.ctid.indexOf("GTM-")!==0)}var ng="/td?id="+Rf.ctid,og=["v","t","pid","dl","tdp"],pg=["mcc"],qg={},rg={};function sg(a,b,c){rg[a]=b;(c===void 0||c)&&tg(a)}function tg(a,b){if(qg[a]===void 0||(b===void 0?0:b))qg[a]=!0}function ug(a){a=a===void 0?!1:a;var b=Object.keys(qg).filter(function(c){return qg[c]===!0&&rg[c]!==void 0&&(a||!pg.includes(c))}).map(function(c){var d=rg[c];typeof d==="function"&&(d=d());return d?"&"+c+"="+d:""}).join("");return""+sf("https://www.googletagmanager.com")+ng+(""+b+"&z=0")}
    function vg(){Object.keys(qg).forEach(function(a){og.indexOf(a)<0&&(qg[a]=!1)})}function wg(a){a=a===void 0?!1:a;if(Af&&Rf.ctid){var b=ug(a);a?bc(b):Sb(b);vg()}}function xg(){Object.keys(qg).filter(function(a){return qg[a]&&!og.includes(a)}).length>0&&wg(!0)}var yg=Ya();function zg(){yg=Ya()}function Ag(){sg("v","3");sg("t","t");sg("pid",function(){return String(yg)});Tb(B,"pagehide",xg);B.setInterval(zg,864E5)}var Bg=!1,Cg=!1;function Dg(){P(46)&&!Cg&&Bg&&Eg("mbc");Cg=!0}function Eg(a){Af&&(sg(a,"1"),wg())}function Fg(a){Qa("HEALTH",a)};var Gg;try{Gg=JSON.parse(Oa("eyIwIjoiQlIiLCIxIjoiQlItTUciLCIyIjpmYWxzZSwiMyI6Imdvb2dsZS5jb20uYnIiLCI0IjoiIiwiNSI6ZmFsc2UsIjYiOmZhbHNlLCI3IjoiYWRfc3RvcmFnZXxhbmFseXRpY3Nfc3RvcmFnZXxhZF91c2VyX2RhdGF8YWRfcGVyc29uYWxpemF0aW9uIn0"))}catch(a){K(123),Fg(2),Gg={}}function Hg(){return Gg["1"]||""}function Ig(){var a=!1;return a}function Jg(){var a="";return a}function Kg(){var a=!1;return a}
    function Lg(){var a="";return a}var Mg=new function(a,b){this.C=a;this.defaultValue=b===void 0?!1:b}(1933);function Ng(){var a=Jb("google_tag_data",{});return a.ics=a.ics||new Og}var Og=function(){this.entries={};this.waitPeriodTimedOut=this.wasSetLate=this.accessedAny=this.accessedDefault=this.usedImplicit=this.usedUpdate=this.usedDefault=this.usedDeclare=this.active=!1;this.C=[]};
    Og.prototype.default=function(a,b,c,d,e,f,k){this.usedDefault||this.usedDeclare||!this.accessedDefault&&!this.accessedAny||(this.wasSetLate=!0);this.usedDefault=this.active=!0;Qa("TAGGING",19);b==null?Qa("TAGGING",18):Pg(this,a,b==="granted",c,d,e,f,k)};Og.prototype.waitForUpdate=function(a,b,c){for(var d=0;d<a.length;d++)Pg(this,a[d],void 0,void 0,"","",b,c)};
    var Pg=function(a,b,c,d,e,f,k,l){var m=a.entries,n=m[b]||{},p=n.region,q=d&&h(d)?d.toUpperCase():void 0;e=e.toUpperCase();f=f.toUpperCase();if(e===""||q===f||(q===e?p!==f:!q&&!p)){var r=!!(k&&k>0&&n.update===void 0),t={region:q,declare_region:n.declare_region,implicit:n.implicit,default:c!==void 0?c:n.default,declare:n.declare,update:n.update,quiet:r};if(e!==""||n.default!==!1)m[b]=t;r&&B.setTimeout(function(){m[b]===t&&t.quiet&&(Qa("TAGGING",2),a.waitPeriodTimedOut=!0,a.clearTimeout(b,void 0,l),
    a.notifyListeners())},k)}};aa=Og.prototype;aa.clearTimeout=function(a,b,c){var d=[a],e=c.delegatedConsentTypes,f;for(f in e)e.hasOwnProperty(f)&&e[f]===a&&d.push(f);var k=this.entries[a]||{},l=this.getConsentState(a,c);if(k.quiet){k.quiet=!1;for(var m=ia(d),n=m.next();!n.done;n=m.next())Qg(this,n.value)}else if(b!==void 0&&l!==b)for(var p=ia(d),q=p.next();!q.done;q=p.next())Qg(this,q.value)};
    aa.update=function(a,b,c){this.usedDefault||this.usedDeclare||this.usedUpdate||!this.accessedAny||(this.wasSetLate=!0);this.usedUpdate=this.active=!0;if(b!=null){var d=this.getConsentState(a,c),e=this.entries;(e[a]=e[a]||{}).update=b==="granted";this.clearTimeout(a,d,c)}};
    aa.declare=function(a,b,c,d,e){this.usedDeclare=this.active=!0;var f=this.entries,k=f[a]||{},l=k.declare_region,m=c&&h(c)?c.toUpperCase():void 0;d=d.toUpperCase();e=e.toUpperCase();if(d===""||m===e||(m===d?l!==e:!m&&!l)){var n={region:k.region,declare_region:m,declare:b==="granted",implicit:k.implicit,default:k.default,update:k.update,quiet:k.quiet};if(d!==""||k.declare!==!1)f[a]=n}};
    aa.implicit=function(a,b){this.usedImplicit=!0;var c=this.entries,d=c[a]=c[a]||{};d.implicit!==!1&&(d.implicit=b==="granted")};
    aa.getConsentState=function(a,b){var c=this.entries,d=c[a]||{},e=d.update;if(e!==void 0)return e?1:2;if(vb(7)){var f=b.containerScopedDefaults[a];if(f===3)return 1;if(f===2)return 2}e=d.default;if(e!==void 0)return e?1:2;if(b==null?0:b.delegatedConsentTypes.hasOwnProperty(a)){var k=b.delegatedConsentTypes[a],l=c[k]||{};e=l.update;if(e!==void 0)return e?1:2;if(vb(7)){var m=b.containerScopedDefaults[k];if(m===3)return 1;if(m===2)return 2}e=l.default;if(e!==void 0)return e?1:2}e=d.declare;if(e!==void 0)return e?
    1:2;e=d.implicit;return e!==void 0?e?3:4:0};aa.addListener=function(a,b){this.C.push({consentTypes:a,Ak:b})};var Qg=function(a,b){for(var c=0;c<a.C.length;++c){var d=a.C[c];Array.isArray(d.consentTypes)&&d.consentTypes.indexOf(b)!==-1&&(d.Ci=!0)}};Og.prototype.notifyListeners=function(a,b){for(var c=0;c<this.C.length;++c){var d=this.C[c];if(d.Ci){d.Ci=!1;try{d.Ak({consentEventId:a,consentPriorityId:b})}catch(e){}}}};var Rg=function(a){Rg[" "](a);return a};Rg[" "]=function(){};var Tg=function(){var a=Sg,b="Uf";if(a.Uf&&a.hasOwnProperty(b))return a.Uf;var c=new a;return a.Uf=c};var Sg=function(){var a={};this.C=function(){var b=Mg.C,c=Mg.defaultValue;return a[b]!=null?a[b]:c};this.D=function(){a[Mg.C]=!0}};var Ug=!1,Vg=!1,Wg={},Xg={delegatedConsentTypes:{},corePlatformServices:{},usedCorePlatformServices:!1,selectedAllCorePlatformServices:!1,containerScopedDefaults:(Wg.ad_storage=1,Wg.analytics_storage=1,Wg.ad_user_data=1,Wg.ad_personalization=1,Wg),usedContainerScopedDefaults:!1};function Yg(a){var b=Ng();b.accessedAny=!0;return(h(a)?[a]:a).every(function(c){switch(b.getConsentState(c,Xg)){case 1:case 3:return!0;case 2:case 4:return!1;default:return!0}})}
    function Zg(a){var b=Ng();b.accessedAny=!0;return b.getConsentState(a,Xg)}function $g(a){for(var b={},c=ia(a),d=c.next();!d.done;d=c.next()){var e=d.value;b[e]=Xg.corePlatformServices[e]!==!1}return b}function ah(a){var b=Ng();b.accessedAny=!0;return!(b.entries[a]||{}).quiet}function bh(){if(!Tg().C())return!1;var a=Ng();a.accessedAny=!0;return a.active||Xg.usedContainerScopedDefaults}function ch(a,b){Ng().addListener(a,b)}function dh(a,b){Ng().notifyListeners(a,b)}
    function eh(a,b){function c(){for(var e=0;e<b.length;e++)if(!ah(b[e]))return!0;return!1}if(c()){var d=!1;ch(b,function(e){d||c()||(d=!0,a(e))})}else a({})}
    function fh(a,b){function c(){for(var l=[],m=0;m<e.length;m++){var n=e[m];Yg(n)&&!f[n]&&l.push(n)}return l}function d(l){for(var m=0;m<l.length;m++)f[l[m]]=!0}var e=h(b)?[b]:b,f={},k=c();k.length!==e.length&&(d(k),ch(e,function(l){function m(q){q.length!==0&&(d(q),l.consentTypes=q,a(l))}var n=c();if(n.length!==0){var p=Object.keys(f).length;n.length+p>=e.length?m(n):B.setTimeout(function(){m(c())},500)}}))};var gh=[L.g.N,L.g.P,L.g.M,L.g.na],hh,ih;function jh(a){for(var b=a[L.g.Od],c=Array.isArray(b)?b:[b],d={zd:0};d.zd<c.length;d={zd:d.zd},++d.zd)ab(a,function(e){return function(f,k){if(f!==L.g.Od){var l=c[e.zd],m=Gg["0"]||"",n=Hg();Vg=!0;Ug&&Qa("TAGGING",20);Ng().declare(f,k,l,m,n)}}}(d))}
    function kh(a){Dg();!ih&&hh&&Eg("crc");ih=!0;var b=a[L.g.Od];b&&K(40);var c=a[L.g.sg];c&&K(41);for(var d=Array.isArray(b)?b:[b],e={Bd:0};e.Bd<d.length;e={Bd:e.Bd},++e.Bd)ab(a,function(f){return function(k,l){if(k!==L.g.Od&&k!==L.g.sg){var m=d[f.Bd],n=Number(c),p=Gg["0"]||"",q=Hg();n=n===void 0?0:n;Ug=!0;Vg&&Qa("TAGGING",20);Ng().default(k,l,m,p,q,n,Xg)}}}(e))}function lh(a,b){Dg();hh=!0;ab(a,function(c,d){Ug=!0;Vg&&Qa("TAGGING",20);Ng().update(c,d,Xg)});dh(b.eventId,b.priorityId)}
    function S(a){Array.isArray(a)||(a=[a]);return a.every(function(b){return Yg(b)})}function mh(a,b){ch(a,b)}function nh(a,b){fh(a,b)}function oh(a,b){eh(a,b)}function ph(){var a=[L.g.N,L.g.na,L.g.M];Ng().waitForUpdate(a,500,Xg)}function qh(a){for(var b=ia(a),c=b.next();!c.done;c=b.next()){var d=c.value;Ng().clearTimeout(d,void 0,Xg)}dh()}var rh=function(){if(ue.pscdl===void 0){var a=function(b){ue.pscdl=b};try{"cookieDeprecationLabel"in Fb?(a("pending"),Fb.cookieDeprecationLabel.getValue().then(a)):a("noapi")}catch(b){a("error")}}};var sh=/[A-Z]+/,th=/\s/;function uh(a,b){if(h(a)){a=fb(a);var c=a.indexOf("-");if(!(c<0)){var d=a.substring(0,c);if(sh.test(d)){var e=a.substring(c+1),f;if(b){var k=function(n){var p=n.indexOf("/");return p<0?[n]:[n.substring(0,p),n.substring(p+1)]};f=k(e);if(d==="DC"&&f.length===2){var l=k(f[1]);l.length===2&&(f[1]=l[0],f.push(l[1]))}}else{f=e.split("/");for(var m=0;m<f.length;m++)if(!f[m]||th.test(f[m])&&(d!=="AW"||m!==1))return}return{id:a,prefix:d,da:d+"-"+f[0],fa:f}}}}}
    function vh(a,b){for(var c={},d=0;d<a.length;++d){var e=uh(a[d],b);e&&(c[e.id]=e)}wh(c);var f=[];ab(c,function(k,l){f.push(l)});return f}function wh(a){var b=[],c;for(c in a)if(a.hasOwnProperty(c)){var d=a[c];d.prefix==="AW"&&d.fa[xh[2]]&&b.push(d.da)}for(var e=0;e<b.length;++e)delete a[b[e]]}var yh={},xh=(yh[0]=0,yh[1]=0,yh[2]=1,yh[3]=0,yh[4]=1,yh[5]=2,yh[6]=0,yh[7]=0,yh[8]=0,yh);var zh=Number('')||500,Ah={},Bh={},Ch={initialized:11,complete:12,interactive:13},Dh={},Eh=Object.freeze((Dh[L.g.Ga]=!0,Dh)),Fh=E.location.search.indexOf("?gtm_diagnostics=")>=0||E.location.search.indexOf("&gtm_diagnostics=")>=0,Gh=void 0;
    function Hh(a,b){if(b.length&&Af){var c;(c=Ah)[a]!=null||(c[a]=[]);Bh[a]!=null||(Bh[a]=[]);var d=b.filter(function(e){return!Bh[a].includes(e)});Ah[a].push.apply(Ah[a],la(d));Bh[a].push.apply(Bh[a],la(d));!Gh&&d.length>0&&(tg("tdc",!0),Gh=B.setTimeout(function(){wg();Ah={};Gh=void 0},zh))}}
    function Ih(a,b,c){if(Af&&a==="config"){var d,e=(d=uh(b))==null?void 0:d.fa;if(!(e&&e.length>1)){var f,k=Jb("google_tag_data",{});k.td||(k.td={});f=k.td;var l=g(c.J);g(c.C,l);var m=[],n;for(n in f)if(f.hasOwnProperty(n)){var p=Jh(f[n],l);p.length&&(Fh&&console.log(p),m.push(n))}m.length&&(Hh(b,m),Qa("TAGGING",Ch[E.readyState]||14));f[b]=l}}}function Kh(a,b){var c={},d;for(d in b)b.hasOwnProperty(d)&&(c[d]=!0);for(var e in a)a.hasOwnProperty(e)&&(c[e]=!0);return c}
    function Jh(a,b,c,d){c=c===void 0?{}:c;d=d===void 0?"":d;if(a===b)return[];var e=function(r,t){var u;Ea(t)==="object"?u=t[r]:Ea(t)==="array"&&(u=t[r]);return u===void 0?Eh[r]:u},f=Kh(a,b),k;for(k in f)if(f.hasOwnProperty(k)){var l=(d?d+".":"")+k,m=e(k,a),n=e(k,b),p=Ea(m)==="object"||Ea(m)==="array",q=Ea(n)==="object"||Ea(n)==="array";if(p&&q)Jh(m,n,c,l);else if(p||q||m!==n)c[l]=!0}return Object.keys(c)}
    function Lh(){sg("tdc",function(){Gh&&(B.clearTimeout(Gh),Gh=void 0);var a=[],b;for(b in Ah)Ah.hasOwnProperty(b)&&a.push(b+"*"+Ah[b].join("."));return a.length?a.join("!"):void 0},!1)};var Mh=function(a,b,c,d,e,f,k,l,m,n,p){this.eventId=a;this.priorityId=b;this.C=c;this.O=d;this.F=e;this.J=f;this.D=k;this.eventMetadata=l;this.onSuccess=m;this.onFailure=n;this.isGtmEvent=p},Nh=function(a,b){var c=[];switch(b){case 3:c.push(a.C);c.push(a.O);c.push(a.F);c.push(a.J);c.push(a.D);break;case 2:c.push(a.C);break;case 1:c.push(a.O);c.push(a.F);c.push(a.J);c.push(a.D);break;case 4:c.push(a.C),c.push(a.O),c.push(a.F),c.push(a.J)}return c},M=function(a,b,c,d){for(var e=ia(Nh(a,d===void 0?3:
    d)),f=e.next();!f.done;f=e.next()){var k=f.value;if(k[b]!==void 0)return k[b]}return c},Oh=function(a){for(var b={},c=Nh(a,4),d=ia(c),e=d.next();!e.done;e=d.next())for(var f=Object.keys(e.value),k=ia(f),l=k.next();!l.done;l=k.next())b[l.value]=1;return Object.keys(b)},Ph=function(a,b,c){function d(n){Ha(n)&&ab(n,function(p,q){f=!0;e[p]=q})}var e={},f=!1,k=Nh(a,c===void 0?3:c);k.reverse();for(var l=ia(k),m=l.next();!m.done;m=l.next())d(m.value[b]);return f?e:void 0},Qh=function(a){for(var b=[L.g.Tc,
    L.g.Pc,L.g.Qc,L.g.Rc,L.g.Sc,L.g.Uc,L.g.Vc],c=Nh(a,3),d=ia(c),e=d.next();!e.done;e=d.next()){for(var f=e.value,k={},l=!1,m=ia(b),n=m.next();!n.done;n=m.next()){var p=n.value;f[p]!==void 0&&(k[p]=f[p],l=!0)}var q=l?k:void 0;if(q)return q}return{}},Rh=function(a,b){this.eventId=a;this.priorityId=b;this.D={};this.O={};this.C={};this.F={};this.T={};this.J={};this.eventMetadata={};this.isGtmEvent=!1;this.onSuccess=function(){};this.onFailure=function(){}},Sh=function(a,b){a.D=b;return a},Th=function(a,
    b){a.O=b;return a},Uh=function(a,b){a.C=b;return a},Vh=function(a,b){a.F=b;return a},Wh=function(a,b){a.T=b;return a},Xh=function(a,b){a.J=b;return a},Yh=function(a,b){a.eventMetadata=b||{};return a},Zh=function(a,b){a.onSuccess=b;return a},$h=function(a,b){a.onFailure=b;return a},ai=function(a,b){a.isGtmEvent=b;return a},bi=function(a){return new Mh(a.eventId,a.priorityId,a.D,a.O,a.C,a.F,a.J,a.eventMetadata,a.onSuccess,a.onFailure,a.isGtmEvent)};var ci={Oi:Number("5"),Tm:Number("")},di=[];function ei(a){di.push(a)}var fi="?id="+Rf.ctid,gi=void 0,hi={},ii=void 0,ji=new function(){var a=5;ci.Oi>0&&(a=ci.Oi);this.D=a;this.C=0;this.F=[]},ki=1E3;
    function li(a,b){var c=gi;if(c===void 0)if(b)c=Ke();else return"";for(var d=[sf("https://www.googletagmanager.com"),"/a",fi],e=ia(di),f=e.next();!f.done;f=e.next())for(var k=f.value,l=k({eventId:c,Ib:!!a}),m=ia(l),n=m.next();!n.done;n=m.next()){var p=ia(n.value),q=p.next().value,r=p.next().value;d.push("&"+q+"="+r)}d.push("&z=0");return d.join("")}
    function mi(){ii&&(B.clearTimeout(ii),ii=void 0);if(gi!==void 0&&ni){var a;(a=hi[gi])||(a=ji.C<ji.D?!1:hb()-ji.F[ji.C%ji.D]<1E3);if(a||ki--<=0)K(1),hi[gi]=!0;else{var b=ji.C++%ji.D;ji.F[b]=hb();var c=li(!0);Sb(c);ni=!1}}}var ni=!1;function oi(a){hi[a]||(a!==gi&&(mi(),gi=a),ni=!0,ii||(ii=B.setTimeout(mi,500)),li().length>=2022&&mi())}var pi=Ya();function qi(){pi=Ya()}function ri(){return[["v","3"],["t","t"],["pid",String(pi)]]}var si={};function ti(a,b,c){zf&&a!==void 0&&(si[a]=si[a]||[],si[a].push(c+b),oi(a))}function ui(a){var b=a.eventId,c=a.Ib,d=[],e=si[b]||[];e.length&&d.push(["epr",e.join(".")]);c&&delete si[b];return d};function vi(a,b,c,d){var e=uh(c,d.isGtmEvent);e&&wi.push("event",[b,a],e,d)}function xi(a,b,c,d){var e=uh(c,d.isGtmEvent);e&&wi.push("get",[a,b],e,d)}
    var yi=function(){this.J={};this.C={};this.O={};this.T=null;this.F={};this.D=!1;this.status=1},zi=function(a,b,c,d){this.D=hb();this.C=b;this.args=c;this.messageContext=d;this.type=a},Ai=function(){this.destinations={};this.D={};this.C=[]},Bi=function(a,b){var c=b.da;return a.destinations[c]=a.destinations[c]||new yi},Ci=function(a,b,c,d){if(d.C){var e=Bi(a,d.C),f=e.T;if(f){var k=g(c,null),l=g(e.J[d.C.id],null),m=g(e.F,null),n=g(e.C,null),p=g(a.D,null),q={};if(zf)try{q=g(Qe)}catch(v){K(72)}var r=
    d.C.prefix,t=function(v){ti(d.messageContext.eventId,r,v)},u=bi(ai($h(Zh(Yh(Wh(Vh(Xh(Uh(Th(Sh(new Rh(d.messageContext.eventId,d.messageContext.priorityId),k),l),m),n),p),q),d.messageContext.eventMetadata),function(){if(t){var v=t;t=void 0;v("2");if(d.messageContext.onSuccess)d.messageContext.onSuccess()}}),function(){if(t){var v=t;t=void 0;v("3");if(d.messageContext.onFailure)d.messageContext.onFailure()}}),!!d.messageContext.isGtmEvent));try{ti(d.messageContext.eventId,r,"1"),Ih(d.type,d.C.id,u),
    f(d.C.id,b,d.D,u)}catch(v){ti(d.messageContext.eventId,r,"4")}}}};Ai.prototype.register=function(a,b,c){var d=Bi(this,a);d.status!==3&&(d.T=b,d.status=3,c&&(g(d.C,c),d.C=c),this.flush())};Ai.prototype.push=function(a,b,c,d){c!==void 0&&(Bi(this,c).status===1&&(Bi(this,c).status=2,this.push("require",[{}],c,{})),Bi(this,c).D&&(d.deferrable=!1));this.C.push(new zi(a,c,b,d));d.deferrable||this.flush()};
    Ai.prototype.flush=function(a){for(var b=this,c=[],d=!1,e={};this.C.length;e={Tb:void 0,Lf:void 0}){var f=this.C[0],k=f.C;if(f.messageContext.deferrable)!k||Bi(this,k).D?(f.messageContext.deferrable=!1,this.C.push(f)):c.push(f),this.C.shift();else{switch(f.type){case "require":if(Bi(this,k).status!==3&&!a){this.C.push.apply(this.C,c);return}break;case "set":ab(f.args[0],function(r,t){g(ob(r,t),b.D)});break;case "config":var l=Bi(this,k);e.Tb={};ab(f.args[0],function(r){return function(t,u){g(ob(t,
    u),r.Tb)}}(e));var m=!!e.Tb[L.g.Rb];delete e.Tb[L.g.Rb];var n=k.da===k.id;m||(n?l.F={}:l.J[k.id]={});l.D&&m||Ci(this,L.g.X,e.Tb,f);l.D=!0;n?g(e.Tb,l.F):(g(e.Tb,l.J[k.id]),K(70));d=!0;break;case "event":e.Lf={};ab(f.args[0],function(r){return function(t,u){g(ob(t,u),r.Lf)}}(e));Ci(this,f.args[1],e.Lf,f);break;case "get":var p={},q=(p[L.g.kb]=f.args[0],p[L.g.vb]=f.args[1],p);Ci(this,L.g.Ma,q,f)}this.C.shift();Di(this,f)}}this.C.push.apply(this.C,c);d&&this.flush()};
    var Di=function(a,b){if(b.type!=="require")if(b.C)for(var c=Bi(a,b.C).O[b.type]||[],d=0;d<c.length;d++)c[d]();else for(var e in a.destinations)if(a.destinations.hasOwnProperty(e)){var f=a.destinations[e];if(f&&f.O)for(var k=f.O[b.type]||[],l=0;l<k.length;l++)k[l]()}},Ei=function(a,b){var c=wi,d=g(b,null);g(Bi(c,a).C,d);Bi(c,a).C=d},wi=new Ai;var Fi=function(a,b){var c=function(){};c.prototype=a.prototype;var d=new c;a.apply(d,Array.prototype.slice.call(arguments,1));return d},Gi=function(a){var b=a;return function(){if(b){var c=b;b=null;c()}}};var Hi=function(a,b,c){a.addEventListener&&a.addEventListener(b,c,!1)},Ii=function(a,b,c){a.removeEventListener&&a.removeEventListener(b,c,!1)};var Ji,Ki;a:{for(var Li=["CLOSURE_FLAGS"],Mi=xa,Ni=0;Ni<Li.length;Ni++)if(Mi=Mi[Li[Ni]],Mi==null){Ki=null;break a}Ki=Mi}var Oi=Ki&&Ki[610401301];Ji=Oi!=null?Oi:!1;var Pi,Qi=xa.navigator;Pi=Qi?Qi.userAgentData||null:null;function Ri(a){return Ji?Pi?Pi.brands.some(function(b){var c;return(c=b.brand)&&c.indexOf(a)!=-1}):!1:!1}function Si(a){var b;a:{var c=xa.navigator;if(c){var d=c.userAgent;if(d){b=d;break a}}b=""}return b.indexOf(a)!=-1};function Ti(){return Ji?!!Pi&&Pi.brands.length>0:!1}function Ui(){return Ti()?Ri("Chromium"):(Si("Chrome")||Si("CriOS"))&&!(Ti()?0:Si("Edge"))||Si("Silk")};var Vi=function(a,b,c,d){for(var e=b,f=c.length;(e=a.indexOf(c,e))>=0&&e<d;){var k=a.charCodeAt(e-1);if(k==38||k==63){var l=a.charCodeAt(e+f);if(!l||l==61||l==38||l==35)return e}e+=f+1}return-1},Wi=/#|$/,Xi=function(a,b){var c=a.search(Wi),d=Vi(a,0,b,c);if(d<0)return null;var e=a.indexOf("&",d);if(e<0||e>c)e=c;d+=b.length+1;return decodeURIComponent(a.slice(d,e!==-1?e:0).replace(/\+/g," "))},Yi=/[?&]($|#)/,Zi=function(a,b,c){for(var d,e=a.search(Wi),f=0,k,l=[];(k=Vi(a,f,b,e))>=0;)l.push(a.substring(f,
    k)),f=Math.min(a.indexOf("&",k)+1||e,e);l.push(a.slice(f));d=l.join("").replace(Yi,"$1");var m,n=c!=null?"="+encodeURIComponent(String(c)):"";var p=b+n;if(p){var q,r=d.indexOf("#");r<0&&(r=d.length);var t=d.indexOf("?"),u;t<0||t>r?(t=r,u=""):u=d.substring(t+1,r);q=[d.slice(0,t),u,d.slice(r)];var v=q[1];q[1]=p?v?v+"&"+p:p:v;m=q[0]+(q[1]?"?"+q[1]:"")+q[2]}else m=d;return m};var $i=function(a){try{var b;if(b=!!a&&a.location.href!=null)a:{try{Rg(a.foo);b=!0;break a}catch(c){}b=!1}return b}catch(c){return!1}},aj=function(a,b){if(a)for(var c in a)Object.prototype.hasOwnProperty.call(a,c)&&b(a[c],c,a)},bj=function(a){if(B.top==B)return 0;if(a===void 0?0:a){var b=B.location.ancestorOrigins;if(b)return b[b.length-1]==B.location.origin?1:2}return $i(B.top)?1:2},cj=function(a){a=a===void 0?document:a;return a.createElement("img")};function dj(a,b,c,d){d=d===void 0?!1:d;a.google_image_requests||(a.google_image_requests=[]);var e=cj(a.document);if(c){var f=function(){if(c){var k=a.google_image_requests,l=Bb(k,e);l>=0&&Array.prototype.splice.call(k,l,1)}Ii(e,"load",f);Ii(e,"error",f)};Hi(e,"load",f);Hi(e,"error",f)}d&&(e.attributionSrc="");e.src=b;a.google_image_requests.push(e)}
    var fj=function(a){var b;b=b===void 0?!1:b;var c="https://pagead2.googlesyndication.com/pagead/gen_204?id=tcfe";aj(a,function(d,e){if(d||d===0)c+="&"+e+"="+encodeURIComponent(""+d)});ej(c,b)},ej=function(a,b){var c=window,d;b=b===void 0?!1:b;d=d===void 0?!1:d;if(c.fetch){var e={keepalive:!0,credentials:"include",redirect:"follow",method:"get",mode:"no-cors"};d&&(e.mode="cors","setAttributionReporting"in XMLHttpRequest.prototype?e.attributionReporting={eventSourceEligible:"true",triggerEligible:"false"}:
    e.headers={"Attribution-Reporting-Eligible":"event-source"});c.fetch(a,e)}else dj(c,a,b===void 0?!1:b,d===void 0?!1:d)};var gj=function(){this.O=this.O;this.D=this.D};gj.prototype.O=!1;gj.prototype.dispose=function(){this.O||(this.O=!0,this.Ha())};gj.prototype[Symbol.dispose]=function(){this.dispose()};gj.prototype.addOnDisposeCallback=function(a,b){this.O?b!==void 0?a.call(b):a():(this.D||(this.D=[]),this.D.push(b!==void 0?Aa(a,b):a))};gj.prototype.Ha=function(){if(this.D)for(;this.D.length;)this.D.shift()()};var hj=function(a){a.addtlConsent!==void 0&&typeof a.addtlConsent!=="string"&&(a.addtlConsent=void 0);a.gdprApplies!==void 0&&typeof a.gdprApplies!=="boolean"&&(a.gdprApplies=void 0);return a.tcString!==void 0&&typeof a.tcString!=="string"||a.listenerId!==void 0&&typeof a.listenerId!=="number"?2:a.cmpStatus&&a.cmpStatus!=="error"?0:3},ij=function(a,b){b=b===void 0?{}:b;gj.call(this);this.F=a;this.C=null;this.T={};this.Ac=0;var c;this.Eb=(c=b.Ul)!=null?c:500;var d;this.Db=(d=b.Em)!=null?d:!1;this.J=
    null};va(ij,gj);ij.prototype.Ha=function(){this.T={};this.J&&(Ii(this.F,"message",this.J),delete this.J);delete this.T;delete this.F;delete this.C;gj.prototype.Ha.call(this)};var kj=function(a){return typeof a.F.__tcfapi==="function"||jj(a)!=null};
    ij.prototype.addEventListener=function(a){var b=this,c={internalBlockOnErrors:this.Db},d=Gi(function(){return a(c)}),e=0;this.Eb!==-1&&(e=setTimeout(function(){c.tcString="tcunavailable";c.internalErrorState=1;d()},this.Eb));var f=function(k,l){clearTimeout(e);k?(c=k,c.internalErrorState=hj(c),c.internalBlockOnErrors=b.Db,l&&c.internalErrorState===0||(c.tcString="tcunavailable",l||(c.internalErrorState=3))):(c.tcString="tcunavailable",c.internalErrorState=3);a(c)};try{lj(this,"addEventListener",f)}catch(k){c.tcString=
    "tcunavailable",c.internalErrorState=3,e&&(clearTimeout(e),e=0),d()}};ij.prototype.removeEventListener=function(a){a&&a.listenerId&&lj(this,"removeEventListener",null,a.listenerId)};
    var nj=function(a,b,c){var d;d=d===void 0?"755":d;var e;a:{if(a.publisher&&a.publisher.restrictions){var f=a.publisher.restrictions[b];if(f!==void 0){e=f[d===void 0?"755":d];break a}}e=void 0}var k=e;if(k===0)return!1;var l=c;c===2?(l=0,k===2&&(l=1)):c===3&&(l=1,k===1&&(l=0));var m;if(l===0)if(a.purpose&&a.vendor){var n=mj(a.vendor.consents,d===void 0?"755":d);m=n&&b==="1"&&a.purposeOneTreatment&&a.publisherCC==="CH"?!0:n&&mj(a.purpose.consents,b)}else m=!0;else m=l===1?a.purpose&&a.vendor?mj(a.purpose.legitimateInterests,
    b)&&mj(a.vendor.legitimateInterests,d===void 0?"755":d):!0:!0;return m},mj=function(a,b){return!(!a||!a[b])},lj=function(a,b,c,d){c||(c=function(){});if(typeof a.F.__tcfapi==="function"){var e=a.F.__tcfapi;e(b,2,c,d)}else if(jj(a)){oj(a);var f=++a.Ac;a.T[f]=c;if(a.C){var k={};a.C.postMessage((k.__tcfapiCall={command:b,version:2,callId:f,parameter:d},k),"*")}}else c({},!1)},jj=function(a){if(a.C)return a.C;var b;a:{for(var c=a.F,d=0;d<50;++d){var e;try{e=!(!c.frames||!c.frames.__tcfapiLocator)}catch(l){e=
    !1}if(e){b=c;break a}var f;b:{try{var k=c.parent;if(k&&k!=c){f=k;break b}}catch(l){}f=null}if(!(c=f))break}b=null}a.C=b;return a.C},oj=function(a){a.J||(a.J=function(b){try{var c;c=(typeof b.data==="string"?JSON.parse(b.data):b.data).__tcfapiReturn;a.T[c.callId](c.returnValue,c.success)}catch(d){}},Hi(a.F,"message",a.J))},pj=function(a){if(a.gdprApplies===!1)return!0;a.internalErrorState===void 0&&(a.internalErrorState=hj(a));return a.cmpStatus==="error"||a.internalErrorState!==0?a.internalBlockOnErrors?
    (fj({e:String(a.internalErrorState)}),!1):!0:a.cmpStatus!=="loaded"||a.eventStatus!=="tcloaded"&&a.eventStatus!=="useractioncomplete"?!1:!0};var qj={1:0,3:0,4:0,7:3,9:3,10:3};function rj(){var a=ue.tcf||{};return ue.tcf=a}var sj=function(){return new ij(B,{Ul:-1})};
    function tj(){var a=rj(),b=sj();kj(b)&&!uj()&&!vj()&&K(124);if(!a.active&&kj(b)){uj()&&(a.active=!0,a.Hb={},a.cmpId=0,a.tcfPolicyVersion=0,Ng().active=!0,a.tcString="tcunavailable");ph();try{b.addEventListener(function(c){if(c.internalErrorState!==0)wj(a),qh([L.g.N,L.g.na,L.g.M]),Ng().active=!0;else if(a.gdprApplies=c.gdprApplies,a.cmpId=c.cmpId,a.enableAdvertiserConsentMode=c.enableAdvertiserConsentMode,vj()&&(a.active=!0),!xj(c)||uj()||vj()){a.tcfPolicyVersion=c.tcfPolicyVersion;var d;if(c.gdprApplies===
    !1){var e={},f;for(f in qj)qj.hasOwnProperty(f)&&(e[f]=!0);d=e;b.removeEventListener(c)}else if(xj(c)){var k={},l;for(l in qj)if(qj.hasOwnProperty(l))if(l==="1"){var m,n=c,p={Ek:!0};p=p===void 0?{}:p;m=pj(n)?n.gdprApplies===!1?!0:n.tcString==="tcunavailable"?!p.ui:(p.ui||n.gdprApplies!==void 0||p.Ek)&&(p.ui||typeof n.tcString==="string"&&n.tcString.length)?nj(n,"1",0):!0:!1;k["1"]=m}else k[l]=nj(c,l,qj[l]);d=k}if(d){a.tcString=c.tcString||"tcempty";a.Hb=d;var q={},r=(q[L.g.N]=a.Hb["1"]?"granted":
    "denied",q);a.gdprApplies!==!0?(qh([L.g.N,L.g.na,L.g.M]),Ng().active=!0):(r[L.g.na]=a.Hb["3"]&&a.Hb["4"]?"granted":"denied",typeof a.tcfPolicyVersion==="number"&&a.tcfPolicyVersion>=4?r[L.g.M]=a.Hb["1"]&&a.Hb["7"]?"granted":"denied":qh([L.g.M]),lh(r,{eventId:0},{gdprApplies:a?a.gdprApplies:void 0,tcString:yj()||""}))}}else qh([L.g.N,L.g.na,L.g.M])})}catch(c){wj(a),qh([L.g.N,L.g.na,L.g.M]),Ng().active=!0}}}function wj(a){a.type="e";a.tcString="tcunavailable"}
    function xj(a){return a.eventStatus==="tcloaded"||a.eventStatus==="useractioncomplete"||a.eventStatus==="cmpuishown"}function uj(){return B.gtag_enable_tcf_support===!0}function vj(){return rj().enableAdvertiserConsentMode===!0}function yj(){var a=rj();if(a.active)return a.tcString}function zj(){var a=rj();if(a.active&&a.gdprApplies!==void 0)return a.gdprApplies?"1":"0"}function Aj(a){if(!qj.hasOwnProperty(String(a)))return!0;var b=rj();return b.active&&b.Hb?!!b.Hb[String(a)]:!0}var Bj=[L.g.N,L.g.P,L.g.M,L.g.na],Cj={},Dj=(Cj[L.g.N]=1,Cj[L.g.P]=2,Cj);function Ej(a){if(a===void 0)return 0;switch(M(a,L.g.ia)){case void 0:return 1;case !1:return 3;default:return 2}}
    var Fj=function(a){if(Hg()==="US-CO"&&Fb.globalPrivacyControl===!0)return!1;var b=Ej(a);if(b===3)return!1;switch(Zg(L.g.na)){case 1:case 3:return!0;case 2:return!1;case 4:return b===2;case 0:return!0;default:return!1}},Gj=function(){return bh()||!Yg(L.g.N)||!Yg(L.g.P)},Hj=function(){var a={},b;for(b in Dj)Dj.hasOwnProperty(b)&&(a[Dj[b]]=Zg(b));return"G1"+hc(a[1]||0)+hc(a[2]||0)},Ij={},Jj=(Ij[L.g.N]=0,Ij[L.g.P]=1,Ij[L.g.M]=2,Ij[L.g.na]=3,Ij);
    function Kj(a){switch(a){case void 0:return 1;case !0:return 3;case !1:return 2;default:return 0}}
    var Lj=function(a){for(var b="1",c=0;c<Bj.length;c++){var d=b,e,f=Bj[c],k=Xg.delegatedConsentTypes[f];e=k===void 0?0:Jj.hasOwnProperty(k)?12|Jj[k]:8;var l=Ng();l.accessedAny=!0;var m=l.entries[f]||{};e=e<<2|Kj(m.implicit);b=d+(""+"0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-_"[e]+"0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-_"[Kj(m.declare)<<4|Kj(m.default)<<2|Kj(m.update)])}var n=b,p=(Hg()==="US-CO"&&Fb.globalPrivacyControl===!0?1:0)<<3,q=(bh()?1:0)<<2,r=Ej(a);
    b=n+"0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-_"[p|q|r];P(83)&&(b+=""+"0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-_"[Xg.containerScopedDefaults.ad_storage<<4|Xg.containerScopedDefaults.analytics_storage<<2|Xg.containerScopedDefaults.ad_user_data]+"0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-_"[Xg.containerScopedDefaults.ad_personalization]);return b},Mj=function(){if(!Yg(L.g.M))return"-";for(var a=Object.keys(se),b=$g(a),c="",d=ia(a),e=
    d.next();!e.done;e=d.next()){var f=e.value;b[f]&&(c+=se[f])}(Xg.usedCorePlatformServices?Xg.selectedAllCorePlatformServices:1)&&(c+="o");return c||"-"},Nj=function(){return Gg["6"]!==!1||(uj()||vj())&&zj()==="1"?"1":"0"},Oj=function(){return(Gg["6"]!==!1?!0:!(!uj()&&!vj())&&zj()==="1")||!Yg(L.g.M)},Pj=function(){var a="0",b="0",c;var d=rj();c=d.active?d.cmpId:void 0;typeof c==="number"&&c>=0&&c<=4095&&(a="0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-_"[c>>6&63],b="0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-_"[c&
    63]);var e="0",f;var k=rj();f=k.active?k.tcfPolicyVersion:void 0;typeof f==="number"&&f>=0&&f<=63&&(e="0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-_"[f]);var l=0;Gg["6"]!==!1&&(l|=1);zj()==="1"&&(l|=2);uj()&&(l|=4);var m;var n=rj();m=n.enableAdvertiserConsentMode!==void 0?n.enableAdvertiserConsentMode?"1":"0":void 0;m==="1"&&(l|=8);Ng().waitPeriodTimedOut&&(l|=16);return"1"+a+b+e+"0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-_"[l]},Qj=function(){return Hg()===
    "US-CO"};function Rj(){var a=!1;return a};var Sj={UA:1,AW:2,DC:3,G:4,GF:5,GT:12,GTM:14,HA:6,MC:7};
    function Tj(a){a=a===void 0?{}:a;var b=Rf.ctid.split("-")[0].toUpperCase(),c={};c.ctid=Rf.ctid;c.Gl=te.qd;c.Kl=te.zf;c.fl=Pf.od?2:1;c.Pl=a.Ji;c.mi=Rf.canonicalContainerId;c.mi!==a.sa&&(c.sa=a.sa);var d=bg();c.sl=d?d.canonicalContainerId:void 0;ze?(c.Re=Sj[b],c.Re||(c.Re=0)):c.Re=De?13:10;Me.F?(c.Pe=0,c.hk=2):Be?c.Pe=1:Rj()?c.Pe=2:c.Pe=3;var e={};e[6]=Qf;c.nk=e;var f=a.Cf,k;var l=c.Re,m=c.Pe;l===void 0?k="":(m||(m=0),k=""+jc(1,1)+gc(l<<2|m));var n=c.hk,p="4"+k+(n?""+jc(2,1)+gc(n):""),q,r=c.Kl;q=r&&
    ic.test(r)?""+jc(3,2)+r:"";var t,u=c.Gl;t=u?""+jc(4,1)+gc(u):"";var v;var w=c.ctid;if(w&&f){var x=w.split("-"),y=x[0].toUpperCase();if(y!=="GTM"&&y!=="OPT")v="";else{var z=x[1];v=""+jc(5,3)+gc(1+z.length)+(c.fl||0)+z}}else v="";var A=c.Pl,C=c.mi,G=c.sa,D=c.Rm,F=p+q+t+v+(A?""+jc(6,1)+gc(A):"")+(C?""+jc(7,3)+gc(C.length)+C:"")+(G?""+jc(8,3)+gc(G.length)+G:"")+(D?""+jc(9,3)+gc(D.length)+D:""),J;var I=c.nk;I=I===void 0?{}:I;for(var Q=[],R=ia(Object.keys(I)),X=R.next();!X.done;X=R.next()){var W=X.value;
    Q[Number(W)]=I[W]}if(Q.length){var O=jc(10,3),ja;if(Q.length===0)ja=gc(0);else{for(var da=[],Y=0,pa=!1,Fa=0;Fa<Q.length;Fa++){pa=!0;var ta=Fa%6;Q[Fa]&&(Y|=1<<ta);ta===5&&(da.push(gc(Y)),Y=0,pa=!1)}pa&&da.push(gc(Y));ja=da.join("")}var Da=ja;J=""+O+gc(Da.length)+Da}else J="";var Ka=c.sl;return F+J+(Ka?""+jc(11,3)+gc(Ka.length)+Ka:"")};var Uj={ai:"service_worker_endpoint",di:"shared_user_id",Vj:"shared_user_id_requested",He:"shared_user_id_source"},Vj;function Wj(a){if(!Vj){Vj={};for(var b=ia(Object.keys(Uj)),c=b.next();!c.done;c=b.next())Vj[Uj[c.value]]=!0}return!!Vj[a]}
    function Xj(a,b){b=b===void 0?!1:b;if(Wj(a)){var c,d,e=(d=(c=Jb("google_tag_data",{})).xcd)!=null?d:c.xcd={};if(e[a])return e[a];if(b){var f=void 0,k=1,l={},m={set:function(n){f=n;m.notify()},get:function(){return f},subscribe:function(n){l[String(k)]=n;return k++},unsubscribe:function(n){var p=String(n);return l.hasOwnProperty(p)?(delete l[p],!0):!1},notify:function(){for(var n=ia(Object.keys(l)),p=n.next();!p.done;p=n.next()){var q=p.value;try{l[q](a,f)}catch(r){}}}};return e[a]=m}}}
    function Yj(){var a=Xj(Uj.Vj,!0);a&&a.set(!0)}function Zj(a){var b;return(b=Xj(a))==null?void 0:b.get()}function ak(a){var b=Uj.He;if(typeof a==="function"){var c;return(c=Xj(b,!0))==null?void 0:c.subscribe(a)}}function bk(a){var b=Xj(Uj.He);b&&b.unsubscribe(a)};function ck(a){return a.origin!=="null"};function dk(a,b,c,d){var e;if(ek(d)){for(var f=[],k=String(b||fk()).split(";"),l=0;l<k.length;l++){var m=k[l].split("="),n=m[0].replace(/^\s*|\s*$/g,"");if(n&&n===a){var p=m.slice(1).join("=").replace(/^\s*|\s*$/g,"");p&&c&&(p=decodeURIComponent(p));f.push(p)}}e=f}else e=[];return e}
    function gk(a,b,c,d,e){if(ek(e)){var f=hk(a,d,e);if(f.length===1)return f[0].id;if(f.length!==0){f=ik(f,function(k){return k.xk},b);if(f.length===1)return f[0].id;f=ik(f,function(k){return k.vl},c);return f[0]?f[0].id:void 0}}}function jk(a,b,c,d){var e=fk(),f=window;ck(f)&&(f.document.cookie=a);var k=fk();return e!==k||c!==void 0&&dk(b,k,!1,d).indexOf(c)>=0}
    function kk(a,b,c){function d(t,u,v){if(v==null)return delete k[u],t;k[u]=v;return t+"; "+u+"="+v}function e(t,u){if(u==null)return t;k[u]=!0;return t+"; "+u}if(!ek(c.Xa))return 2;var f;b==null?f=a+"=deleted; expires="+(new Date(0)).toUTCString():(c.encode&&(b=encodeURIComponent(b)),b=lk(b),f=a+"="+b);var k={};f=d(f,"path",c.path);var l;c.expires instanceof Date?l=c.expires.toUTCString():c.expires!=null&&(l=""+c.expires);f=d(f,"expires",l);f=d(f,"max-age",c.Lm);f=d(f,"samesite",c.Pm);c.Qm&&(f=e(f,
    "secure"));var m=c.domain;if(m&&m.toLowerCase()==="auto"){for(var n=mk(),p=0;p<n.length;++p){var q=n[p]!=="none"?n[p]:void 0,r=d(f,"domain",q);r=e(r,c.flags);if(!nk(q,c.path)&&jk(r,a,b,c.Xa))return 0}return 1}m&&m.toLowerCase()!=="none"&&(f=d(f,"domain",m));f=e(f,c.flags);return nk(m,c.path)?1:jk(f,a,b,c.Xa)?0:1}function ok(a,b,c){c.path==null&&(c.path="/");c.domain||(c.domain="auto");return kk(a,b,c)}
    function ik(a,b,c){for(var d=[],e=[],f,k=0;k<a.length;k++){var l=a[k],m=b(l);m===c?d.push(l):f===void 0||m<f?(e=[l],f=m):m===f&&e.push(l)}return d.length>0?d:e}function hk(a,b,c){for(var d=[],e=dk(a,void 0,void 0,c),f=0;f<e.length;f++){var k=e[f].split("."),l=k.shift();if(!b||!l||b.indexOf(l)!==-1){var m=k.shift();if(m){var n=m.split("-");d.push({id:k.join("."),xk:Number(n[0])||1,vl:Number(n[1])||1})}}}return d}function lk(a){a&&a.length>1200&&(a=a.substring(0,1200));return a}
    var pk=/^(www\.)?google(\.com?)?(\.[a-z]{2})?$/,qk=/(^|\.)doubleclick\.net$/i;function nk(a,b){return a!==void 0&&(qk.test(window.document.location.hostname)||b==="/"&&pk.test(a))}function rk(a){if(!a)return 1;a=a.indexOf(".")===0?a.substring(1):a;return a.split(".").length}function sk(a){if(!a||a==="/")return 1;a[0]!=="/"&&(a="/"+a);a[a.length-1]!=="/"&&(a+="/");return a.split("/").length-1}function tk(a,b){var c=""+rk(a),d=sk(b);d>1&&(c+="-"+d);return c}
    var fk=function(){return ck(window)?window.document.cookie:""},ek=function(a){return a&&Tg().C()?(Array.isArray(a)?a:[a]).every(function(b){return ah(b)&&Yg(b)}):!0},mk=function(){var a=[],b=window.document.location.hostname.split(".");if(b.length===4){var c=b[b.length-1];if(Number(c).toString()===c)return["none"]}for(var d=b.length-2;d>=0;d--)a.push(b.slice(d).join("."));var e=window.document.location.hostname;qk.test(e)||pk.test(e)||a.push("none");return a};function uk(a){var b=Math.round(Math.random()*2147483647),c;if(a){var d=1,e,f,k;if(a)for(d=0,f=a.length-1;f>=0;f--)k=a.charCodeAt(f),d=(d<<6&268435455)+k+(k<<14),e=d&266338304,d=e!==0?d^e>>21:d;c=String(b^d&2147483647)}else c=String(b);return c}function vk(a){return[uk(a),Math.round(hb()/1E3)].join(".")};function wk(a,b,c,d){var e,f=Number(a.Wa!=null?a.Wa:void 0);f!==0&&(e=new Date((b||hb())+1E3*(f||7776E3)));return{path:a.path,domain:a.domain,flags:a.flags,encode:!!c,expires:e,Xa:d}};var xk;function yk(){function a(k){c(k.target||k.srcElement||{})}function b(k){d(k.target||k.srcElement||{})}var c=zk,d=Ak,e=Bk();if(!e.init){Tb(E,"mousedown",a);Tb(E,"keyup",a);Tb(E,"submit",b);var f=HTMLFormElement.prototype.submit;HTMLFormElement.prototype.submit=function(){d(this);f.call(this)};e.init=!0}}function Ck(a,b,c,d,e){var f={callback:a,domains:b,fragment:c===2,placement:c,forms:d,sameHost:e};Bk().decorators.push(f)}
    function Dk(a,b,c){for(var d=Bk().decorators,e={},f=0;f<d.length;++f){var k=d[f],l;if(l=!c||k.forms)a:{var m=k.domains,n=a,p=!!k.sameHost;if(m&&(p||n!==E.location.hostname))for(var q=0;q<m.length;q++)if(m[q]instanceof RegExp){if(m[q].test(n)){l=!0;break a}}else if(n.indexOf(m[q])>=0||p&&m[q].indexOf(n)>=0){l=!0;break a}l=!1}if(l){var r=k.placement;r===void 0&&(r=k.fragment?2:1);r===b&&kb(e,k.callback())}}return e}
    function Bk(){var a=Jb("google_tag_data",{}),b=a.gl;b&&b.decorators||(b={decorators:[]},a.gl=b);return b};var Ek=/(.*?)\*(.*?)\*(.*)/,Fk=/^https?:\/\/([^\/]*?)\.?cdn\.ampproject\.org\/?(.*)/,Gk=/^(?:www\.|m\.|amp\.)+/,Hk=/([^?#]+)(\?[^#]*)?(#.*)?/;function Ik(a){var b=Hk.exec(a);if(b)return{gg:b[1],query:b[2],fragment:b[3]}}
    function Jk(a,b){var c=[Fb.userAgent,(new Date).getTimezoneOffset(),Fb.userLanguage||Fb.language,Math.floor(hb()/60/1E3)-(b===void 0?0:b),a].join("*"),d;if(!(d=xk)){for(var e=Array(256),f=0;f<256;f++){for(var k=f,l=0;l<8;l++)k=k&1?k>>>1^3988292384:k>>>1;e[f]=k}d=e}xk=d;for(var m=4294967295,n=0;n<c.length;n++)m=m>>>8^xk[(m^c.charCodeAt(n))&255];return((m^-1)>>>0).toString(36)}
    function Kk(){return function(a){var b=kf(B.location.href),c=b.search.replace("?",""),d=cf(c,"_gl",!0)||"";a.query=Lk(d)||{};var e=df(b,"fragment"),f;var k=-1;if(mb(e,"_gl="))k=4;else{var l=e.indexOf("&_gl=");l>0&&(k=l+3+2)}if(k<0)f=void 0;else{var m=e.indexOf("&",k);f=m<0?e.substring(k):e.substring(k,m)}a.fragment=Lk(f||"")||{}}}function Mk(a){var b=Kk(),c=Bk();c.data||(c.data={query:{},fragment:{}},b(c.data));var d={},e=c.data;e&&(kb(d,e.query),a&&kb(d,e.fragment));return d}
    var Lk=function(a){try{var b=Nk(a,3);if(b!==void 0){for(var c={},d=b?b.split("*"):[],e=0;e+1<d.length;e+=2){var f=d[e],k=Oa(d[e+1]);c[f]=k}Qa("TAGGING",6);return c}}catch(l){Qa("TAGGING",8)}};function Nk(a,b){if(a){var c;a:{for(var d=a,e=0;e<3;++e){var f=Ek.exec(d);if(f){c=f;break a}d=decodeURIComponent(d)}c=void 0}var k=c;if(k&&k[1]==="1"){var l=k[3],m;a:{for(var n=k[2],p=0;p<b;++p)if(n===Jk(l,p)){m=!0;break a}m=!1}if(m)return l;Qa("TAGGING",7)}}}
    function Ok(a,b,c,d,e){function f(p){var q=p,r=(new RegExp("(.*?)(^|&)"+a+"=([^&]*)&?(.*)")).exec(q),t=q;if(r){var u=r[2],v=r[4];t=r[1];v&&(t=t+u+v)}p=t;var w=p.charAt(p.length-1);p&&w!=="&"&&(p+="&");return p+n}d=d===void 0?!1:d;e=e===void 0?!1:e;var k=Ik(c);if(!k)return"";var l=k.query||"",m=k.fragment||"",n=a+"="+b;d?m.substring(1).length!==0&&e||(m="#"+f(m.substring(1))):l="?"+f(l.substring(1));return""+k.gg+l+m}
    function Pk(a,b){function c(n,p,q){var r;a:{for(var t in n)if(n.hasOwnProperty(t)){r=!0;break a}r=!1}if(r){var u,v=[],w;for(w in n)if(n.hasOwnProperty(w)){var x=n[w];x!==void 0&&x===x&&x!==null&&x.toString()!=="[object Object]"&&(v.push(w),v.push(Na(String(x))))}var y=v.join("*");u=["1",Jk(y),y].join("*");d?(vb(3)||vb(1)||!p)&&Qk("_gl",u,a,p,q):Rk("_gl",u,a,p,q)}}var d=(a.tagName||"").toUpperCase()==="FORM",e=Dk(b,1,d),f=Dk(b,2,d),k=Dk(b,4,d),l=Dk(b,3,d);c(e,!1,!1);c(f,!0,!1);vb(1)&&c(k,!0,!0);for(var m in l)l.hasOwnProperty(m)&&
    Sk(m,l[m],a)}function Sk(a,b,c){c.tagName.toLowerCase()==="a"?Rk(a,b,c):c.tagName.toLowerCase()==="form"&&Qk(a,b,c)}function Rk(a,b,c,d,e){d=d===void 0?!1:d;e=e===void 0?!1:e;var f;if(f=c.href){var k;if(!(k=!vb(4)||d)){var l=B.location.href,m=Ik(c.href),n=Ik(l);k=!(m&&n&&m.gg===n.gg&&m.query===n.query&&m.fragment)}f=k}if(f){var p=Ok(a,b,c.href,d,e);Ab.test(p)&&(c.href=p)}}
    function Qk(a,b,c,d,e){d=d===void 0?!1:d;e=e===void 0?!1:e;if(c&&c.action){var f=(c.method||"").toLowerCase();if(f!=="get"||d){if(f==="get"||f==="post"){var k=Ok(a,b,c.action,d,e);Ab.test(k)&&(c.action=k)}}else{for(var l=c.childNodes||[],m=!1,n=0;n<l.length;n++){var p=l[n];if(p.name===a){p.setAttribute("value",b);m=!0;break}}if(!m){var q=E.createElement("input");q.setAttribute("type","hidden");q.setAttribute("name",a);q.setAttribute("value",b);c.appendChild(q)}}}}
    function zk(a){try{var b;a:{for(var c=a,d=100;c&&d>0;){if(c.href&&c.nodeName.match(/^a(?:rea)?$/i)){b=c;break a}c=c.parentNode;d--}b=null}var e=b;if(e){var f=e.protocol;f!=="http:"&&f!=="https:"||Pk(e,e.hostname)}}catch(k){}}function Ak(a){try{if(a.action){var b=df(kf(a.action),"host");Pk(a,b)}}catch(c){}}function Tk(a,b,c,d){yk();var e=c==="fragment"?2:1;d=!!d;Ck(a,b,e,d,!1);e===2&&Qa("TAGGING",23);d&&Qa("TAGGING",24)}function Uk(a,b){yk();Ck(a,[ff(B.location,"host",!0)],b,!0,!0)}
    function Vk(){var a=E.location.hostname,b=Fk.exec(E.referrer);if(!b)return!1;var c=b[2],d=b[1],e="";if(c){var f=c.split("/"),k=f[1];e=k==="s"?decodeURIComponent(f[2]):decodeURIComponent(k)}else if(d){if(d.indexOf("xn--")===0)return!1;e=d.replace(/-/g,".").replace(/\.\./g,"-")}var l=a.replace(Gk,""),m=e.replace(Gk,"");return l===m||nb(l,"."+m)}function Wk(a,b){return a===!1?!1:a||b||Vk()};var Xk=["1"],Yk={},Zk={};function $k(a,b){b=b===void 0?!0:b;var c=al(a.prefix);if(!Yk[c])if(cl(c,a.path,a.domain)){var d=Zk[al(a.prefix)];dl(a,d?d.id:void 0,d?d.cg:void 0)}else{var e=mf("auiddc");e?(Qa("TAGGING",17),Yk[c]=e):b&&(el(al(a.prefix),vk(),a),cl(c,a.path,a.domain))}}function dl(a,b,c){var d=al(a.prefix),e=Yk[d];if(e){var f=e.split(".");if(f.length===2){var k=Number(f[1])||0;if(k){var l=e;b&&(l=e+"."+b+"."+(c?c:Math.floor(hb()/1E3)));el(d,l,a,k*1E3)}}}}
    function el(a,b,c,d){var e=["1",tk(c.domain,c.path),b].join("."),f=wk(c,d);f.Xa=fl();ok(a,e,f)}function cl(a,b,c){var d=gk(a,rk(b),sk(c),Xk,fl());if(!d)return!1;gl(a,d);return!0}function gl(a,b){var c=b.split(".");c.length===5?(Yk[a]=c.slice(0,2).join("."),Zk[a]={id:c.slice(2,4).join("."),cg:Number(c[4])||0}):c.length===3?Zk[a]={id:c.slice(0,2).join("."),cg:Number(c[2])||0}:Yk[a]=b}function al(a){return(a||"_gcl")+"_au"}
    function hl(a){function b(){Yg(c)&&a()}var c=fl();eh(function(){b();Yg(c)||fh(b,c)},c)}function il(a){var b=Mk(!0),c=al(a.prefix);hl(function(){var d=b[c];if(d){gl(c,d);var e=Number(Yk[c].split(".")[1])*1E3;if(e){Qa("TAGGING",16);var f=wk(a,e);f.Xa=fl();ok(c,["1",tk(a.domain,a.path),d].join("."),f)}}})}function jl(a,b,c,d,e){e=e||{};var f=function(){var k={},l=gk(a,rk(e.path),sk(e.domain),Xk,fl());l&&(k[a]=l);return k};hl(function(){Tk(f,b,c,d)})}
    function fl(){return["ad_storage","ad_user_data"]};function kl(a){for(var b=[],c=E.cookie.split(";"),d=new RegExp("^\\s*"+(a||"_gac")+"_(UA-\\d+-\\d+)=\\s*(.+?)\\s*$"),e=0;e<c.length;e++){var f=c[e].match(d);f&&b.push({pg:f[1],value:f[2],timestamp:Number(f[2].split(".")[1])||0})}b.sort(function(k,l){return l.timestamp-k.timestamp});return b}
    function ll(a,b){var c=kl(a),d={};if(!c||!c.length)return d;for(var e=0;e<c.length;e++){var f=c[e].value.split(".");if(!(f[0]!=="1"||b&&f.length<3||!b&&f.length!==3)&&Number(f[1])){d[c[e].pg]||(d[c[e].pg]=[]);var k={version:f[0],timestamp:Number(f[1])*1E3,W:f[2]};b&&f.length>3&&(k.labels=f.slice(3));d[c[e].pg].push(k)}}return d};var ml={},nl=(ml.k={Ea:/^[\w-]+$/},ml.b={Ea:/^[\w-]+$/,kg:!0},ml.i={Ea:/^[1-9]\d*$/},ml);var ol={},rl=(ol[5]={Qi:{2:pl},Ff:["k","i","b"]},ol[4]={Qi:{2:pl,GCL:ql},Ff:["k","i","b"]},ol);function sl(a){var b=rl[5];if(b){var c=a.split(".")[0];if(c){var d=b.Qi[c];if(d)return d(a,5)}}}function pl(a,b){var c=a.split(".");if(c.length===3){var d={},e=rl[b];if(e){for(var f=e.Ff,k=ia(c[2].split("$")),l=k.next();!l.done;l=k.next()){var m=l.value,n=m[0];if(f.indexOf(n)!==-1)try{var p=decodeURIComponent(m.substring(1)),q=nl[n];q&&(q.kg?(d[n]=d[n]||[],d[n].push(p)):d[n]=p)}catch(r){}}return d}}}
    function tl(a,b){var c=rl[5];if(c){for(var d=[],e=ia(c.Ff),f=e.next();!f.done;f=e.next()){var k=f.value,l=nl[k];if(l){var m=a[k];if(m!==void 0)if(l.kg&&Array.isArray(m))for(var n=ia(m),p=n.next();!p.done;p=n.next())d.push(encodeURIComponent(""+k+p.value));else d.push(encodeURIComponent(""+k+m))}}return["2",b||"1",d.join("$")].join(".")}}function ql(a){var b=a.split(".");b.shift();var c=b.shift(),d=b.shift(),e={};return e.k=d,e.i=c,e.b=b,e};var ul=new Map([[5,"ad_storage"],[4,["ad_storage","ad_user_data"]]]);function vl(a){if(rl[5]){for(var b=[],c=dk(a,void 0,void 0,ul.get(5)),d=ia(c),e=d.next();!e.done;e=d.next()){var f=sl(e.value);f&&(wl(f),b.push(f))}return b}}function xl(a,b,c,d){c=c||{};var e=tl(b,tk(c.domain,c.path));if(e){var f=wk(c,d,void 0,ul.get(5));ok(a,e,f)}}function yl(a,b){var c=b.Ea;return typeof c==="function"?c(a):c.test(a)}
    function wl(a){for(var b=ia(Object.keys(a)),c=b.next(),d={};!c.done;d={wd:void 0},c=b.next()){var e=c.value,f=a[e];d.wd=nl[e];d.wd?d.wd.kg?a[e]=Array.isArray(f)?f.filter(function(k){return function(l){return yl(l,k.wd)}}(d)):void 0:typeof f==="string"&&yl(f,d.wd)||(a[e]=void 0):a[e]=void 0}};var zl=/^\w+$/,Al=/^[\w-]+$/,Bl={},Cl=(Bl.aw="_aw",Bl.dc="_dc",Bl.gf="_gf",Bl.gp="_gp",Bl.gs="_gs",Bl.ha="_ha",Bl.ag="_ag",Bl.gb="_gb",Bl);function Dl(){return["ad_storage","ad_user_data"]}function El(a){return!Tg().C()||Yg(a)}function Fl(a,b){function c(){var d=El(b);d&&a();return d}eh(function(){c()||fh(c,b)},b)}function Gl(a){return Hl(a).map(function(b){return b.W})}function Il(a){return Jl(a).filter(function(b){return b.W}).map(function(b){return b.W})}
    function Jl(a){var b=Kl(a.prefix),c=Ll("gb",b),d=Ll("ag",b);if(!d||!c)return[];var e=function(l){return function(m){m.type=l;return m}},f=Hl(c).map(e("gb")),k=(vb(6)?Ml(d):[]).map(e("ag"));return f.concat(k).sort(function(l,m){return m.timestamp-l.timestamp})}function Nl(a,b,c,d,e){var f=Xa(a,function(k){return k.W===c});f?(f.timestamp=Math.max(f.timestamp,d),f.labels=Ol(f.labels||[],e||[])):a.push({version:b,W:c,timestamp:d,labels:e})}
    function Ml(a){for(var b=vl(a)||[],c=[],d=ia(b),e=d.next();!e.done;e=d.next()){var f=e.value,k=f,l=Pl(f);l&&Nl(c,"2",k.k,l,k.b||[])}return c.sort(function(m,n){return n.timestamp-m.timestamp})}function Hl(a){for(var b=[],c=dk(a,E.cookie,void 0,Dl()),d=ia(c),e=d.next();!e.done;e=d.next()){var f=Ql(e.value);if(f!=null){var k=f;Nl(b,k.version,k.W,k.timestamp,k.labels)}}b.sort(function(l,m){return m.timestamp-l.timestamp});return Rl(b)}
    function Ol(a,b){if(!a.length)return b;if(!b.length)return a;var c={};return a.concat(b).filter(function(d){return c.hasOwnProperty(d)?!1:c[d]=!0})}function Kl(a){return a&&typeof a==="string"&&a.match(zl)?a:"_gcl"}
    function Sl(a,b){var c=vb(6),d=kf(a),e=df(d,"query",!1,void 0,"gclid"),f=df(d,"query",!1,void 0,"gclsrc"),k=df(d,"query",!1,void 0,"wbraid");k=sb(k);var l;c&&(l=df(d,"query",!1,void 0,"gbraid"));var m=df(d,"query",!1,void 0,"gad_source"),n=df(d,"query",!1,void 0,"dclid");if(b&&(!e||!f||!k||c&&!l)){var p=d.hash.replace("#","");e=e||cf(p,"gclid");f=f||cf(p,"gclsrc");k=k||cf(p,"wbraid");c&&(l=l||cf(p,"gbraid"));m=m||cf(p,"gad_source")}return Tl(e,f,n,k,l,m)}
    function Ul(){return Sl(B.location.href,!0)}
    function Tl(a,b,c,d,e,f){var k={},l=function(m,n){k[n]||(k[n]=[]);k[n].push(m)};k.gclid=a;k.gclsrc=b;k.dclid=c;if(a!==void 0&&a.match(Al))switch(b){case void 0:l(a,"aw");break;case "aw.ds":l(a,"aw");l(a,"dc");break;case "ds":l(a,"dc");break;case "3p.ds":l(a,"dc");break;case "gf":l(a,"gf");break;case "ha":l(a,"ha")}c&&l(c,"dc");d!==void 0&&Al.test(d)&&(k.wbraid=d,l(d,"gb"));vb(6)&&e!==void 0&&Al.test(e)&&(k.gbraid=e,l(e,"ag"));f!==void 0&&Al.test(f)&&(k.gad_source=f,l(f,"gs"));return k}
    function Vl(a){var b=Ul();if(vb(5)){for(var c=!0,d=ia(Object.keys(b)),e=d.next();!e.done;e=d.next())if(b[e.value]!==void 0){c=!1;break}c&&(b=Sl(B.document.referrer,!1))}Wl(b,!1,a)}
    function Wl(a,b,c,d,e){c=c||{};e=e||[];var f=Kl(c.prefix),k=d||hb(),l=Math.round(k/1E3),m=Dl(),n=!1,p=!1,q=function(){if(El(m)){var r=wk(c,k,!0);r.Xa=m;for(var t=function(F,J){var I=Ll(F,f);I&&(ok(I,J,r),F!=="gb"&&(n=!0))},u=function(F){var J=["GCL",l,F];e.length>0&&J.push(e.join("."));return J.join(".")},v=ia(["aw","dc","gf","ha","gp"]),w=v.next();!w.done;w=v.next()){var x=w.value;a[x]&&t(x,u(a[x][0]))}if(!n&&a.gb){var y=a.gb[0],z=Ll("gb",f);!b&&Hl(z).some(function(F){return F.W===y&&F.labels&&F.labels.length>
    0})||t("gb",u(y))}}if(!p&&vb(6)&&a.gbraid&&El("ad_storage")&&(p=!0,!n)){var A=a.gbraid,C=Ll("ag",f);if(b||!(vb(6)?Ml(C):[]).some(function(F){return F.W===A&&F.labels&&F.labels.length>0})){var G={},D=(G.k=A,G.i=""+l,G.b=e,G);xl(C,D,c,k)}}Xl(a,f,k,c)};eh(function(){q();El(m)||fh(q,m)},m)}function Xl(a,b,c,d){if(a.gad_source!==void 0&&El("ad_storage")){var e=Ll("gs",b);if(e){var f=Math.round((hb()-(dc()||0))/1E3),k={},l=(k.k=a.gad_source,k.i=""+f,k);xl(e,l,d,c)}}}
    function Yl(a,b){var c=Mk(!0);Fl(function(){for(var d=Kl(b.prefix),e=0;e<a.length;++e){var f=a[e];if(Cl[f]!==void 0){var k=Ll(f,d),l=c[k];if(l){var m=Math.min(Zl(l),hb()),n;b:{for(var p=m,q=dk(k,E.cookie,void 0,Dl()),r=0;r<q.length;++r)if(Zl(q[r])>p){n=!0;break b}n=!1}if(!n){var t=wk(b,m,!0);t.Xa=Dl();ok(k,l,t)}}}}Wl(Tl(c.gclid,c.gclsrc),!1,b)},Dl())}
    function $l(a){var b=[];vb(6)&&b.push("ag");if(b.length!==0){var c=Mk(!0),d=Kl(a.prefix);Fl(function(){for(var e=0;e<b.length;++e){var f=Ll(b[e],d);if(f){var k=c[f];if(k){var l=sl(k);if(l){var m=Pl(l);m||(m=hb());var n;a:{for(var p=m,q=vl(f),r=0;r<q.length;++r)if(Pl(q[r])>p){n=!0;break a}n=!1}if(n)break;l.i=""+Math.round(m/1E3);xl(f,l,a,m)}}}}},["ad_storage"])}}function Ll(a,b){var c=Cl[a];if(c!==void 0)return b+c}
    function Zl(a){return am(a.split(".")).length!==0?(Number(a.split(".")[1])||0)*1E3:0}function Pl(a){return a?(Number(a.i)||0)*1E3:0}function Ql(a){var b=am(a.split("."));return b.length===0?null:{version:b[0],W:b[2],timestamp:(Number(b[1])||0)*1E3,labels:b.slice(3)}}function am(a){return a.length<3||a[0]!=="GCL"&&a[0]!=="1"||!/^\d+$/.test(a[1])||!Al.test(a[2])?[]:a}
    function bm(a,b,c,d,e){if(Array.isArray(b)&&ck(B)){var f=Kl(e),k=function(){for(var l={},m=0;m<a.length;++m){var n=Ll(a[m],f);if(n){var p=dk(n,E.cookie,void 0,Dl());p.length&&(l[n]=p.sort()[p.length-1])}}return l};Fl(function(){Tk(k,b,c,d)},Dl())}}
    function cm(a,b,c,d){if(Array.isArray(a)&&ck(B)){var e=[];vb(6)&&e.push("ag");if(e.length!==0){var f=Kl(d),k=function(){for(var l={},m=0;m<e.length;++m){var n=Ll(e[m],f);if(!n)return{};var p=vl(n);if(p.length){var q=p.sort(function(r,t){return Pl(t)-Pl(r)})[0];l[n]=tl(q)}}return l};Fl(function(){Tk(k,a,b,c)},["ad_storage"])}}}function Rl(a){return a.filter(function(b){return Al.test(b.W)})}
    function dm(a,b){if(ck(B)){for(var c=Kl(b.prefix),d={},e=0;e<a.length;e++)Cl[a[e]]&&(d[a[e]]=Cl[a[e]]);Fl(function(){ab(d,function(f,k){var l=dk(c+k,E.cookie,void 0,Dl());l.sort(function(t,u){return Zl(u)-Zl(t)});if(l.length){var m=l[0],n=Zl(m),p=am(m.split(".")).length!==0?m.split(".").slice(3):[],q={},r;r=am(m.split(".")).length!==0?m.split(".")[2]:void 0;q[f]=[r];Wl(q,!0,b,n,p)}})},Dl())}}
    function em(a){var b=[],c=[];vb(6)&&(b.push("ag"),c.push("gbraid"));b.length!==0&&Fl(function(){for(var d=Kl(a.prefix),e=0;e<b.length;++e){var f=Ll(b[e],d);if(!f)break;var k=vl(f);if(k.length){var l=k.sort(function(q,r){return Pl(r)-Pl(q)})[0],m=Pl(l),n=l.b,p={};p[c[e]]=l.k;Wl(p,!0,a,m,n)}}},["ad_storage"])}function fm(a,b){for(var c=0;c<b.length;++c)if(a[b[c]])return!0;return!1}
    function gm(a){function b(e,f,k){k&&(e[f]=k)}if(bh()){var c=Ul();if(fm(c,a)){var d={};b(d,"gclid",c.gclid);b(d,"dclid",c.dclid);b(d,"gclsrc",c.gclsrc);b(d,"wbraid",c.wbraid);vb(6)&&b(d,"gbraid",c.gbraid);Uk(function(){return d},3);Uk(function(){var e={};return e._up="1",e},1)}}}
    function hm(a){if(!vb(1))return null;var b=Mk(!0).gad_source;if(b!=null)return B.location.hash="",b;if(vb(2)){var c=kf(B.location.href);b=df(c,"query",!1,void 0,"gad_source");if(b!=null)return b;var d=Ul();if(fm(d,a))return"0"}return null}function im(a){var b=hm(a);b!=null&&Uk(function(){var c={};return c.gad_source=b,c},4)}
    function jm(a,b,c){var d=[];if(b.length===0)return d;for(var e={},f=0;f<b.length;f++){var k=b[f],l=k.type?k.type:"gcl";(k.labels||[]).indexOf(c)===-1?(a.push(0),e[l]||d.push(k)):a.push(1);e[l]=!0}return d}function km(a,b,c,d){var e=[];c=c||{};if(!El(Dl()))return e;var f=Hl(a),k=jm(e,f,b);if(k.length&&!d)for(var l=ia(k),m=l.next();!m.done;m=l.next()){var n=m.value,p=n.timestamp,q=[n.version,Math.round(p/1E3),n.W].concat(n.labels||[],[b]).join("."),r=wk(c,p,!0);r.Xa=Dl();ok(a,q,r)}return e}
    function lm(a,b){var c=[];b=b||{};var d=Jl(b),e=jm(c,d,a);if(e.length)for(var f=ia(e),k=f.next();!k.done;k=f.next()){var l=k.value,m=Kl(b.prefix),n=Ll(l.type,m);if(!n)break;var p=l,q=p.version,r=p.W,t=p.labels,u=p.timestamp,v=Math.round(u/1E3);if(l.type==="ag"){var w={},x=(w.k=r,w.i=""+v,w.b=(t||[]).concat([a]),w);xl(n,x,b,u)}else if(l.type==="gb"){var y=[q,v,r].concat(t||[],[a]).join("."),z=wk(b,u,!0);z.Xa=Dl();ok(n,y,z)}}return c}
    function mm(a,b){var c=Kl(b),d=Ll(a,c);if(!d)return 0;var e;e=a==="ag"?vb(6)?Ml(d):[]:Hl(d);for(var f=0,k=0;k<e.length;k++)f=Math.max(f,e[k].timestamp);return f}function nm(a){for(var b=0,c=ia(Object.keys(a)),d=c.next();!d.done;d=c.next())for(var e=a[d.value],f=0;f<e.length;f++)b=Math.max(b,Number(e[f].timestamp));return b}function om(a,b){var c=Math.max(mm("aw",a),nm(El(Dl())?ll():{})),d=Math.max(mm("gb",a),nm(El(Dl())?ll("_gac_gb",!0):{}));vb(6)&&b&&(d=Math.max(d,mm("ag",a)));return d>c};var Cm,Dm=!1;function Em(a){if(!Dm){Dm=!0;Cm=Cm||{}}return Cm[a]}var Fm=function(a,b,c){this.eventName=b;this.j=c;this.m={};this.isAborted=!1;this.target=a;this.metadata=g(c.eventMetadata||{},{})};Fm.prototype.copyToHitData=function(a,b,c){var d=M(this.j,a);d===void 0&&(d=b);if(d!==void 0&&c!==void 0&&h(d)&&P(58))try{d=c(d)}catch(e){}d!==void 0&&(this.m[a]=d)};var Gm=function(a,b,c){var d=Em(a.target.da);return d&&d[b]!==void 0?d[b]:c};function Hm(){ue.dedupe_gclid||(ue.dedupe_gclid=vk());return ue.dedupe_gclid};var Im=/^(www\.)?google(\.com?)?(\.[a-z]{2}t?)?$/,Jm=/^www.googleadservices.com$/;function Km(a){a||(a=Lm());return a.Xl?!1:a.Ok||a.Pk||a.Rk||a.Qk||a.Sf||a.Nf||a.Dk||a.Gk?!0:!1}
    function Lm(){var a={},b=Mk(!0);a.Xl=!!b._up;var c=Ul();a.Ok=c.aw!==void 0;a.Pk=c.dc!==void 0;a.Rk=c.wbraid!==void 0;a.Qk=c.gbraid!==void 0;var d=kf(B.location.href),e=df(d,"query",!1,void 0,"gad");a.Sf=e!==void 0;if(!a.Sf){var f=d.hash.replace("#",""),k=cf(f,"gad");a.Sf=k!==void 0}a.Nf=df(d,"query",!1,void 0,"gad_source");if(a.Nf===void 0){var l=d.hash.replace("#",""),m=cf(l,"gad_source");a.Nf=m}var n=E.referrer?df(kf(E.referrer),"host"):"";a.Gk=Im.test(n);a.Dk=Jm.test(n);return a};var Mm=RegExp("^UA-\\d+-\\d+%3A[\\w-]+(?:%2C[\\w-]+)*(?:%3BUA-\\d+-\\d+%3A[\\w-]+(?:%2C[\\w-]+)*)*$"),Nm=/^~?[\w-]+(?:\.~?[\w-]+)*$/,Om=/^\d+\.fls\.doubleclick\.net$/,Pm=/;gac=([^;?]+)/,Qm=/;gacgb=([^;?]+)/;
    function Rm(a,b){if(Om.test(E.location.host)){var c=E.location.href.match(b);return c&&c.length===2&&c[1].match(Mm)?decodeURIComponent(c[1]):""}for(var d=[],e=ia(Object.keys(a)),f=e.next();!f.done;f=e.next()){for(var k=f.value,l=[],m=a[k],n=0;n<m.length;n++)l.push(m[n].W);d.push(k+":"+l.join(","))}return d.length>0?d.join(";"):""}
    function Sm(a,b,c){for(var d=El(Dl())?ll("_gac_gb",!0):{},e=[],f=!1,k=ia(Object.keys(d)),l=k.next();!l.done;l=k.next()){var m=l.value,n=km("_gac_gb_"+m,a,b,c);f=f||n.length!==0&&n.some(function(p){return p===1});e.push(m+":"+n.join(","))}return{Ck:f?e.join(";"):"",Bk:Rm(d,Qm)}}function Tm(a){var b=E.location.href.match(new RegExp(";"+a+"=([^;?]+)"));return b&&b.length===2&&b[1].match(Nm)?b[1]:void 0}
    function Um(a){var b={Of:void 0,Pf:void 0},c,d;Om.test(E.location.host)&&(c=Tm("gclgs"),d=Tm("gclst"));if(c&&d)b.Of=c,b.Pf=d;else{var e=hb(),f=Ml((a||"_gcl")+"_gs"),k=f.map(function(m){return m.W}),l=f.map(function(m){return e-m.timestamp});k.length>0&&l.length>0&&(b.Of=k.join("."),b.Pf=l.join("."))}return b}
    function Vm(a,b,c){if(Om.test(E.location.host)){var d=Tm(c);if(d)return[{W:d}]}else{if(b==="gclid")return Hl((a||"_gcl")+"_aw");if(b==="wbraid")return Hl((a||"_gcl")+"_gb");if(b==="braids")return Jl({prefix:a})}return[]}function Wm(a){return Vm(a,"gclid","gclaw").map(function(b){return b.W}).join(".")}function Xm(a){return Vm(a,"wbraid","gclgb").map(function(b){return b.W}).join(".")}function Ym(a){return Vm(a,"braids","gclgb").map(function(b){return b.W}).join(".")}
    function Zm(a,b){return Om.test(E.location.host)?!(Tm("gclaw")||Tm("gac")):om(a,b)}function $m(a,b,c){var d;d=c?lm(a,b):km((b&&b.prefix||"_gcl")+"_gb",a,b);return d.length===0||d.every(function(e){return e===0})?"":d.join(".")};function an(){var a=B.__uspapi;if(Ua(a)){var b="";try{a("getUSPData",1,function(c,d){if(d&&c){var e=c.uspString;e&&RegExp("^[\\da-zA-Z-]{1,20}$").test(e)&&(b=e)}})}catch(c){}return b}};function jn(a){var b=M(a.j,L.g.yb),c=M(a.j,L.g.xb);b&&!c?(a.eventName!==L.g.X&&a.eventName!==L.g.Mc&&K(131),a.isAborted=!0):!b&&c&&(K(132),a.isAborted=!0)}function kn(a){var b=S(L.g.N)?ue.pscdl:"denied";b!=null&&(a.m[L.g.Vd]=b)}function ln(a){var b=bj(!0);a.m[L.g.wb]=b}function mn(a){Qj()&&(a.m[L.g.vc]=1)};function tn(a,b,c,d){var e=Qb(),f;if(e===1)a:{var k=Fe;k=k.toLowerCase();for(var l="https://"+k,m="http://"+k,n=1,p=E.getElementsByTagName("script"),q=0;q<p.length&&q<100;q++){var r=p[q].src;if(r){r=r.toLowerCase();if(r.indexOf(m)===0){f=3;break a}n===1&&r.indexOf(l)===0&&(n=2)}}f=n}else f=e;return(f===2||d||"http:"!=B.location.protocol?a:b)+c};function Gn(a){return{getDestinationId:function(){return a.target.da},getEventName:function(){return a.eventName},setEventName:function(b){a.eventName=b},getHitData:function(b){return a.m[b]},setHitData:function(b,c){a.m[b]=c},setHitDataIfNotDefined:function(b,c){a.m[b]===void 0&&(a.m[b]=c)},copyToHitData:function(b,c){a.copyToHitData(b,c)},getMetadata:function(b){return a.metadata[b]},setMetadata:function(b,c){a.metadata[b]=c},isAborted:function(){return a.isAborted},abort:function(){a.isAborted=
    !0},getFromEventContext:function(b){return M(a.j,b)},Km:function(){return a},getHitKeys:function(){return Object.keys(a.m)}}};function Mn(){var a=B.screen;return{width:a?a.width:0,height:a?a.height:0}}
    function Nn(a){if(E.hidden)return!0;var b=a.getBoundingClientRect();if(b.top===b.bottom||b.left===b.right||!B.getComputedStyle)return!0;var c=B.getComputedStyle(a,null);if(c.visibility==="hidden")return!0;for(var d=a,e=c;d;){if(e.display==="none")return!0;var f=e.opacity,k=e.filter;if(k){var l=k.indexOf("opacity(");l>=0&&(k=k.substring(l+8,k.indexOf(")",l)),k.charAt(k.length-1)==="%"&&(k=k.substring(0,k.length-1)),f=String(Math.min(Number(k),Number(f))))}if(f!==void 0&&Number(f)<=0)return!0;(d=d.parentElement)&&
    (e=B.getComputedStyle(d,null))}return!1}var Ho=Number('')||5,Io=Number('')||50,Jo=Ya();var Oo={Yj:Number('')||500,Lj:Number('')||5E3,Ph:Number('20')||10,Ui:Number('')||5E3};function Po(a){return a.performance&&a.performance.now()||Date.now()}
    var Qo=function(a,b){var c;return c};var Ro="https://"+te.Kc+"/gtm/static/",So;
    function Xo(a,b){}
    var Yo=function(a,b,c,d){};
    function Zo(a,b,c,d){}
    function $o(a,b,c,d){}var ap=void 0;function bp(a){var b=[];return b};!Si("Android")||Ui();Ui();Si("Safari")&&(Ui()||(Ti()?0:Si("Coast"))||(Ti()?0:Si("Opera"))||(Ti()?0:Si("Edge"))||(Ti()?Ri("Microsoft Edge"):Si("Edg/"))||Ti()&&Ri("Opera"));var cp={},dp=null,ep=function(a){for(var b=[],c=0,d=0;d<a.length;d++){var e=a.charCodeAt(d);e>255&&(b[c++]=e&255,e>>=8);b[c++]=e}var f=4;f===void 0&&(f=0);if(!dp){dp={};for(var k="ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789".split(""),l=["+/=","+/","-_=","-_.","-_"],m=0;m<5;m++){var n=k.concat(l[m].split(""));cp[m]=n;for(var p=0;p<n.length;p++){var q=n[p];dp[q]===void 0&&(dp[q]=p)}}}for(var r=cp[f],t=Array(Math.floor(b.length/3)),u=r[64]||"",v=0,w=0;v<b.length-2;v+=3){var x=b[v],
    y=b[v+1],z=b[v+2],A=r[x>>2],C=r[(x&3)<<4|y>>4],G=r[(y&15)<<2|z>>6],D=r[z&63];t[w++]=""+A+C+G+D}var F=0,J=u;switch(b.length-v){case 2:F=b[v+1],J=r[(F&15)<<2]||u;case 1:var I=b[v];t[w]=""+r[I>>2]+r[(I&3)<<4|F>>4]+J+u}return t.join("")};var fp="platform platformVersion architecture model uaFullVersion bitness fullVersionList wow64".split(" ");function gp(){var a;return(a=B.google_tag_data)!=null?a:B.google_tag_data={}}function hp(){var a=B.google_tag_data,b;if(a!=null&&a.uach){var c=a.uach,d=Object.assign({},c);c.fullVersionList&&(d.fullVersionList=c.fullVersionList.slice(0));b=d}else b=null;return b}function ip(){var a,b;return(b=(a=B.google_tag_data)==null?void 0:a.uach_promise)!=null?b:null}
    function jp(){var a,b;return typeof((a=B.navigator)==null?void 0:(b=a.userAgentData)==null?void 0:b.getHighEntropyValues)==="function"}function kp(){if(!jp())return null;var a=gp();if(a.uach_promise)return a.uach_promise;var b=B.navigator.userAgentData.getHighEntropyValues(fp).then(function(c){a.uach!=null||(a.uach=c);return c});return a.uach_promise=b};function qp(a){var b;b=b===void 0?document:b;var c;return!((c=b.featurePolicy)==null||!c.allowedFeatures().includes(a))};function rp(){return qp("join-ad-interest-group")&&Ua(Fb.joinAdInterestGroup)}
    function sp(a,b){var c=ub[3]===void 0?1:ub[3],d='iframe[data-tagging-id="'+b+'"]',e=[];try{if(c===1){var f=E.querySelector(d);f&&(e=[f])}else e=Array.from(E.querySelectorAll(d))}catch(q){}var k;a:{try{k=E.querySelectorAll('iframe[allow="join-ad-interest-group"][data-tagging-id*="-"]');break a}catch(q){}k=void 0}var l=k,m=((l==null?void 0:l.length)||0)>=(ub[2]===void 0?50:ub[2]),n;if(n=e.length>=1){var p=Number(e[e.length-1].dataset.loadTime);p!==void 0&&hb()-p<(ub[1]===void 0?6E4:ub[1])?(Qa("TAGGING",
    9),n=!0):n=!1}if(!n){if(c===1)if(e.length>=1)tp(e[0]);else{if(m){Qa("TAGGING",10);return}}else e.length>=c?tp(e[0]):m&&tp(l[0]);Rb(a,void 0,{allow:"join-ad-interest-group"},{taggingId:b,loadTime:hb()})}}function tp(a){try{a.parentNode.removeChild(a)}catch(b){}}function up(){return"https://td.doubleclick.net"};var nq={H:{rg:"ads_conversion_hit",Jc:"container_execute_start",wg:"container_setup_end",Ue:"container_setup_start",ug:"container_blocking_end",vg:"container_execute_end",xg:"container_yield_end",Ve:"container_yield_start",Eh:"event_execute_end",Dh:"event_evaluation_end",wf:"event_evaluation_start",Fh:"event_setup_end",nd:"event_setup_start",Hh:"ga4_conversion_hit",pd:"page_load",sm:"pageview",Fb:"snippet_load",fi:"tag_callback_error",gi:"tag_callback_failure",hi:"tag_callback_success",ii:"tag_execute_end",
    Bc:"tag_execute_start"}};function oq(){function a(c,d){var e=Ra(d);e&&b.push([c,e])}var b=[];a("u","GTM");a("ut","TAGGING");a("h","HEALTH");return b};var pq=!1;function Yq(a,b){}
    function Zq(a,b){}function $q(a,b){}
    function ar(a,b){}function br(){var a={};return a}
    function Qq(a){a=a===void 0?!0:a;var b={};return b}
    function cr(){}function dr(a,b){}
    function er(a,b,c){}function fr(a,b){var c,d=B.GooglebQhCsO;d||(d={},B.GooglebQhCsO=d);c=d;if(c[a])return!1;c[a]=[];c[a][0]=b;return!0};var gr=function(a,b,c){var d=Xi(a,"fmt");if(b){var e=Xi(a,"random"),f=Xi(a,"label")||"";if(!e)return!1;var k=ep(decodeURIComponent(f.replace(/\+/g," "))+":"+decodeURIComponent(e.replace(/\+/g," ")));if(!fr(k,b))return!1}d&&d!=4&&(a=Zi(a,"rfmt",d));var l=Zi(a,"fmt",4);Pb(l,function(){B.google_noFurtherRedirects&&b&&b.call&&(B.google_noFurtherRedirects=null,b())},void 0,c,E.getElementsByTagName("script")[0].parentElement||void 0);return!0};function xr(a,b){if(data.entities){var c=data.entities[a];if(c)return c[b]}};function yr(a){var b=Zf(),c;c=c===void 0?!1:c;zr().addRestriction(0,b,a,c)}function Ar(a){var b=Zf(),c;c=c===void 0?!1:c;zr().addRestriction(1,b,a,c)}function Br(){var a=Zf();return zr().getRestrictions(1,a)}var Cr=function(){this.C={};this.D={}},Dr=function(a,b){var c=a.C[b];c||(c={_entity:{internal:[],external:[]},_event:{internal:[],external:[]}},a.C[b]=c);return c};
    Cr.prototype.addRestriction=function(a,b,c,d){d=d===void 0?!1:d;if(!d||!this.D[b]){var e=Dr(this,b);a===0?d?e._entity.external.push(c):e._entity.internal.push(c):a===1&&(d?e._event.external.push(c):e._event.internal.push(c))}};
    Cr.prototype.getRestrictions=function(a,b){var c=Dr(this,b);if(a===0){var d,e;return[].concat(la((c==null?void 0:(d=c._entity)==null?void 0:d.internal)||[]),la((c==null?void 0:(e=c._entity)==null?void 0:e.external)||[]))}if(a===1){var f,k;return[].concat(la((c==null?void 0:(f=c._event)==null?void 0:f.internal)||[]),la((c==null?void 0:(k=c._event)==null?void 0:k.external)||[]))}return[]};
    Cr.prototype.getExternalRestrictions=function(a,b){var c=Dr(this,b),d,e;return a===0?(c==null?void 0:(d=c._entity)==null?void 0:d.external)||[]:(c==null?void 0:(e=c._event)==null?void 0:e.external)||[]};Cr.prototype.removeExternalRestrictions=function(a){var b=Dr(this,a);b._event&&(b._event.external=[]);b._entity&&(b._entity.external=[]);this.D[a]=!0};function zr(){var a=ue.r;a||(a=new Cr,ue.r=a);return a};var Er=new RegExp(/^(.*\.)?(google|youtube|blogger|withgoogle)(\.com?)?(\.[a-z]{2})?\.?$/),Fr={cl:["ecl"],customPixels:["nonGooglePixels"],ecl:["cl"],ehl:["hl"],gaawc:["googtag"],hl:["ehl"],html:["customScripts","customPixels","nonGooglePixels","nonGoogleScripts","nonGoogleIframes"],customScripts:["html","customPixels","nonGooglePixels","nonGoogleScripts","nonGoogleIframes"],nonGooglePixels:[],nonGoogleScripts:["nonGooglePixels"],nonGoogleIframes:["nonGooglePixels"]},Gr={cl:["ecl"],customPixels:["customScripts",
    "html"],ecl:["cl"],ehl:["hl"],gaawc:["googtag"],hl:["ehl"],html:["customScripts"],customScripts:["html"],nonGooglePixels:["customPixels","customScripts","html","nonGoogleScripts","nonGoogleIframes"],nonGoogleScripts:["customScripts","html"],nonGoogleIframes:["customScripts","html","nonGoogleScripts"]},Hr="google customPixels customScripts html nonGooglePixels nonGoogleScripts nonGoogleIframes".split(" ");
    function Ir(){var a=Te("gtm.allowlist")||Te("gtm.whitelist");a&&K(9);ze&&(a=["google","gtagfl","lcl","zone"]);Er.test(B.location&&B.location.hostname)&&(ze?K(116):(K(117),Jr&&(a=[],window.console&&window.console.log&&window.console.log("GTM blocked. See go/13687728."))));var b=a&&lb(eb(a),Fr),c=Te("gtm.blocklist")||Te("gtm.blacklist");c||(c=Te("tagTypeBlacklist"))&&K(3);c?K(8):c=[];Er.test(B.location&&B.location.hostname)&&(c=eb(c),c.push("nonGooglePixels","nonGoogleScripts","sandboxedScripts"));
    eb(c).indexOf("google")>=0&&K(2);var d=c&&lb(eb(c),Gr),e={};return function(f){var k=f&&f[kc.ma];if(!k||typeof k!=="string")return!0;k=k.replace(/^_*/,"");if(e[k]!==void 0)return e[k];var l=Je[k]||[],m=!0;if(a){var n;if(n=m)a:{if(b.indexOf(k)<0)if(l&&l.length>0)for(var p=0;p<l.length;p++){if(b.indexOf(l[p])<0){K(11);n=!1;break a}}else{n=!1;break a}n=!0}m=n}var q=!1;if(c){var r=d.indexOf(k)>=0;if(r)q=r;else{var t=Za(d,l||[]);t&&K(10);q=t}}var u=!m||q;u||!(l.indexOf("sandboxedScripts")>=0)||b&&b.indexOf("sandboxedScripts")!==
    -1||(u=Za(d,Hr));return e[k]=u}}var Jr=!1;Jr=!0;
    function Kr(){Qf&&yr(function(a){var b={};b[kc.ma]="__"+a.entityId;for(var c in void 0)(void 0).hasOwnProperty(c)&&(b["vtp_"+c]=(void 0)[c]);var d;if(Wc(b)){var e=b[kc.ma];if(!e)throw Error("Error: No function name given for function call.");var f=Mc[e];d=!!f&&!!f.runInSiloedMode}else d=!!xr(b[kc.ma],4);return d})}function Lr(a,b,c,d){if(!Mr()){var e=d.siloed?Uf(a):a;if(!Nf().container[e]){var f="?id="+encodeURIComponent(a)+"&l="+te.Ya,k=mb(a,"GTM-");k||(f+="&cx=c");P(62)&&(f+="&gtm="+Tj());var l=qf();l&&(f+="&sign="+te.Ge);var m=c?"/gtag/js":"/gtm.js",n=pf(b,m+f);if(!n){var p=te.Kc+m;l&&Ib&&k?(p=Ib.replace(/^(?:https?:\/\/)?/i,"").split(/[?#]/)[0],n=tn("https://","http://",p+f)):n=Me.D?Ne()+m+f:tn("https://","http://",p+f)}d.siloed&&jg({ctid:e,isDestination:!1});var q=dg();Nf().container[e]={state:1,context:d,
    parent:q};Mf({ctid:e,isDestination:!1});Pb(n)}}}
    function Nr(a,b,c){if(!Mr()){var d=c.siloed?Uf(a):a,e=Nf().destination[d];if(!e||!e.state)if(!c.siloed&&kg())Nf().destination[d]={state:0,transportUrl:b,context:c,parent:dg()},Mf({ctid:d,isDestination:!0}),K(91);else{var f="/gtag/destination?id="+encodeURIComponent(a)+"&l="+te.Ya+"&cx=c";P(62)&&(f+="&gtm="+Tj());qf()&&(f+="&sign="+te.Ge);var k=pf(b,f);k||(k=Me.D?Ne()+f:tn("https://","http://",te.Kc+f));c.siloed&&jg({ctid:d,isDestination:!0});Nf().destination[d]={state:1,context:c,parent:dg()};Mf({ctid:d,
    isDestination:!0});Pb(k)}}}function Mr(){if(Rj()){return!0}return!1};var Or=[];function Pr(){var a=Rf.ctid;if(a){var b=Pf.od?1:0,c,d=cg(dg());c=d&&d.context;return a+";"+Rf.canonicalContainerId+";"+(c&&c.fromContainerExecution?1:0)+";"+(c&&c.source||0)+";"+b}}function Qr(){var a=kf(B.location.href);return a.hostname+a.pathname}function Rr(){var a=Qr();a&&sg("dl",encodeURIComponent(a));if(P(78)){var b=Pr();b&&sg("tdp",b)}else sg("tdp",function(){return Or.length>0?Or.join("."):void 0});var c=bj(!0);c!==void 0&&sg("frm",String(c))};var Sr=!1,Tr=0,Ur=[];function Vr(a){if(!Sr){var b=E.createEventObject,c=E.readyState==="complete",d=E.readyState==="interactive";if(!a||a.type!=="readystatechange"||c||!b&&d){Sr=!0;for(var e=0;e<Ur.length;e++)H(Ur[e])}Ur.push=function(){for(var f=wa.apply(0,arguments),k=0;k<f.length;k++)H(f[k]);return 0}}}function Wr(){if(!Sr&&Tr<140){Tr++;try{var a,b;(b=(a=E.documentElement).doScroll)==null||b.call(a,"left");Vr()}catch(c){B.setTimeout(Wr,50)}}}function Xr(a){Sr?a():Ur.push(a)};function Yr(a,b){return{entityType:1,indexInOriginContainer:a,nameInOriginContainer:b,originContainerId:Xf()}};var $r=function(a,b){this.C=!1;this.J=[];this.eventData={tags:[]};this.O=!1;this.D=this.F=0;Zr(this,a,b)},as=function(a,b,c,d){if(we.hasOwnProperty(b)||b==="__zone")return-1;var e={};Ha(d)&&(e=g(d,e));e.id=c;e.status="timeout";return a.eventData.tags.push(e)-1},bs=function(a,b,c,d){var e=a.eventData.tags[b];e&&(e.status=c,e.executionTime=d)},cs=function(a){if(!a.C){for(var b=a.J,c=0;c<b.length;c++)b[c]();a.C=!0;a.J.length=0}},Zr=function(a,b,c){b!==void 0&&ds(a,b);c&&B.setTimeout(function(){cs(a)},
    Number(c))},ds=function(a,b){var c=jb(function(){H(function(){b(Xf(),a.eventData)})});a.C?c():a.J.push(c)},es=function(a){a.F++;return jb(function(){a.D++;a.O&&a.D>=a.F&&cs(a)})},fs=function(a){a.O=!0;a.D>=a.F&&cs(a)};var gs={},is=function(){return B[hs()]};
    function hs(){return B.GoogleAnalyticsObject||"ga"}
    var ls=function(){var a=Xf();},ms=function(a,b){return function(){var c=is(),d=c&&c.getByName&&c.getByName(a);if(d){var e=d.get("sendHitTask");d.set("sendHitTask",function(f){var k=f.get("hitPayload"),l=f.get("hitCallback"),m=k.indexOf("&tid="+b)<0;m&&(f.set("hitPayload",k.replace(/&tid=UA-[0-9]+-[0-9]+/,"&tid="+b),!0),f.set("hitCallback",void 0,!0));e(f);
    m&&(f.set("hitPayload",k,!0),f.set("hitCallback",l,!0),f.set("_x_19",void 0,!0),e(f))})}}};var rs=["es","1"],ss={},ts={};function us(a,b){if(zf){var c;c=b.match(/^(gtm|gtag)\./)?encodeURIComponent(b):"*";ss[a]=[["e",c],["eid",a]];oi(a)}}function vs(a){var b=a.eventId,c=a.Ib;if(!ss[b])return[];var d=[];ts[b]||d.push(rs);d.push.apply(d,la(ss[b]));c&&(ts[b]=!0);return d};var ws={},xs={},ys={};function zs(a,b,c,d){zf&&P(72)&&((d===void 0?0:d)?(ys[b]=ys[b]||0,++ys[b]):c!==void 0?(xs[a]=xs[a]||{},xs[a][b]=Math.round(c)):(ws[a]=ws[a]||{},ws[a][b]=(ws[a][b]||0)+1))}function As(a){var b=a.eventId,c=a.Ib,d=ws[b]||{},e=[],f;for(f in d)d.hasOwnProperty(f)&&e.push(""+f+d[f]);c&&delete ws[b];return e.length?[["md",e.join(".")]]:[]}
    function Bs(a){var b=a.eventId,c=a.Ib,d=xs[b]||{},e=[],f;for(f in d)d.hasOwnProperty(f)&&e.push(""+f+d[f]);c&&delete xs[b];return e.length?[["mtd",e.join(".")]]:[]}function Cs(){for(var a=[],b=ia(Object.keys(ys)),c=b.next();!c.done;c=b.next()){var d=c.value;a.push(""+d+ys[d])}return a.length?[["mec",a.join(".")]]:[]};var Ds={},Es={};function Fs(a,b,c){if(zf&&b){var d=tf(b);Ds[a]=Ds[a]||[];Ds[a].push(c+d);var e=(Wc(b)?"1":"2")+d;Es[a]=Es[a]||[];Es[a].push(e);oi(a)}}function Gs(a){var b=a.eventId,c=a.Ib,d=[],e=Ds[b]||[];e.length&&d.push(["tr",e.join(".")]);var f=Es[b]||[];f.length&&d.push(["ti",f.join(".")]);c&&(delete Ds[b],delete Es[b]);return d};function Hs(a,b,c,d){var e=Kc[a],f=Is(a,b,c,d);if(!f)return null;var k=Xc(e[kc.bi],c,[]);if(k&&k.length){var l=k[0];f=Hs(l.index,{onSuccess:f,onFailure:l.ri===1?b.terminate:f,terminate:b.terminate},c,d)}return f}
    function Is(a,b,c,d){function e(){function w(){Fg(3);var D=hb()-G;Fs(c.id,f,"7");bs(c.Sb,A,"exception",D);P(63)&&er(c,f,nq.H.fi);C||(C=!0,l())}if(f[kc.Pj])l();else{var x=Vc(f,c,[]),y=x[kc.Si];if(y!=null)for(var z=0;z<y.length;z++)if(!S(y[z])){l();return}var A=as(c.Sb,String(f[kc.ma]),Number(f[kc.sd]),x[kc.Qj]),C=!1;x.vtp_gtmOnSuccess=function(){if(!C){C=!0;var D=hb()-G;Fs(c.id,Kc[a],"5");bs(c.Sb,A,"success",D);P(63)&&er(c,f,nq.H.hi);k()}};x.vtp_gtmOnFailure=function(){if(!C){C=!0;var D=hb()-G;Fs(c.id,
    Kc[a],"6");bs(c.Sb,A,"failure",D);P(63)&&er(c,f,nq.H.gi);l()}};x.vtp_gtmTagId=f.tag_id;x.vtp_gtmEventId=c.id;c.priorityId&&(x.vtp_gtmPriorityId=c.priorityId);Fs(c.id,f,"1");P(63)&&dr(c,f);var G=hb();try{Yc(x,{event:c,index:a,type:1})}catch(D){w(D)}P(63)&&er(c,f,nq.H.ii)}}var f=Kc[a],k=b.onSuccess,l=b.onFailure,m=b.terminate;if(c.isBlocked(f))return null;var n=Xc(f[kc.ji],c,[]);if(n&&n.length){var p=n[0],q=Hs(p.index,{onSuccess:k,onFailure:l,terminate:m},c,d);if(!q)return null;k=q;l=p.ri===2?m:q}if(f[kc.Sh]||
    f[kc.Sj]){var r=f[kc.Sh]?Lc:c.Ql,t=k,u=l;if(!r[a]){var v=Js(a,r,jb(e));k=v.onSuccess;l=v.onFailure}return function(){r[a](t,u)}}return e}function Js(a,b,c){var d=[],e=[];b[a]=Ks(d,e,c);return{onSuccess:function(){b[a]=Ls;for(var f=0;f<d.length;f++)d[f]()},onFailure:function(){b[a]=Ms;for(var f=0;f<e.length;f++)e[f]()}}}function Ks(a,b,c){return function(d,e){a.push(d);b.push(e);c()}}function Ls(a){a()}function Ms(a,b){b()};var Ps=function(a,b){for(var c=[],d=0;d<Kc.length;d++)if(a[d]){var e=Kc[d];var f=es(b.Sb);try{var k=Hs(d,{onSuccess:f,onFailure:f,terminate:f},b,d);if(k){var l=e[kc.ma];if(!l)throw Error("Error: No function name given for function call.");var m=Mc[l];c.push({Ki:d,Di:(m?m.priorityOverride||0:0)||xr(e[kc.ma],1)||0,execute:k})}else Ns(d,b),f()}catch(p){f()}}c.sort(Os);for(var n=0;n<c.length;n++)c[n].execute();return c.length>
    0};function Os(a,b){var c,d=b.Di,e=a.Di;c=d>e?1:d<e?-1:0;var f;if(c!==0)f=c;else{var k=a.Ki,l=b.Ki;f=k>l?1:k<l?-1:0}return f}
    function Ns(a,b){if(zf){var c=function(d){var e=b.isBlocked(Kc[d])?"3":"4",f=Xc(Kc[d][kc.bi],b,[]);f&&f.length&&c(f[0].index);Fs(b.id,Kc[d],e);var k=Xc(Kc[d][kc.ji],b,[]);k&&k.length&&c(k[0].index)};c(a)}}var Ss=!1,Qs;
    var Xs=function(a){var b=a["gtm.uniqueEventId"],c=a["gtm.priorityId"],d=a.event;if(P(63)){}if(d==="gtm.js"){if(Ss)return!1;Ss=!0}var e=!1,f=Br(),k=g(a);if(!f.every(function(t){return t({originalEventData:k})})){if(d!=="gtm.js"&&d!=="gtm.init"&&d!=="gtm.init_consent")return!1;e=!0}us(b,d);var l=a.eventCallback,m=a.eventTimeout,n={id:b,
    priorityId:c,name:d,isBlocked:Ts(k,e),Ql:[],logMacroError:function(){K(6);Fg(0)},cachedModelValues:Us(),Sb:new $r(function(){if(P(63)){}l&&
    l.apply(l,[].slice.call(arguments,0))},m),originalEventData:k};P(72)&&zf&&(n.reportMacroDiscrepancy=zs);P(63)&&$q(n.id,n.name);var p=Zc(n);P(63)&&ar(n.id,n.name);e&&(p=Vs(p));if(P(63)){}var q=Ps(p,n),r=!1;fs(n.Sb);d!=="gtm.js"&&d!=="gtm.sync"||ls();return Ws(p,q)||r};function Us(){var a={};a.event=Xe("event",1);a.ecommerce=Xe("ecommerce",1);a.gtm=Xe("gtm");a.eventModel=Xe("eventModel");return a}
    function Ts(a,b){var c=Ir();return function(d){if(c(d))return!0;var e=d&&d[kc.ma];if(!e||typeof e!="string")return!0;e=e.replace(/^_*/,"");var f,k=Zf();f=zr().getRestrictions(0,k);var l=a;b&&(l=g(a),l["gtm.uniqueEventId"]=Number.MAX_SAFE_INTEGER);for(var m=Je[e]||[],n=ia(f),p=n.next();!p.done;p=n.next()){var q=p.value;try{if(!q({entityId:e,securityGroups:m,originalEventData:l}))return!0}catch(r){return!0}}return!1}}
    function Vs(a){for(var b=[],c=0;c<a.length;c++)if(a[c]){var d=String(Kc[c][kc.ma]);if(ve[d]||Kc[c][kc.Tj]!==void 0||xr(d,2))b[c]=!0}return b}function Ws(a,b){if(!b)return b;for(var c=0;c<a.length;c++)if(a[c]&&Kc[c]&&!we[String(Kc[c][kc.ma])])return!0;return!1}var Ys=0;function Zs(a,b){return arguments.length===1?$s("config",a):$s("config",a,b)}function at(a,b,c){c=c||{};c[L.g.Qb]=a;return $s("event",b,c)}function $s(){return arguments};var bt=function(){this.messages=[];this.C=[]};bt.prototype.enqueue=function(a,b,c){var d=this.messages.length+1;a["gtm.uniqueEventId"]=b;a["gtm.priorityId"]=d;var e=Object.assign({},c,{eventId:b,priorityId:d,fromContainerExecution:!0}),f={message:a,notBeforeEventId:b,priorityId:d,messageContext:e};this.messages.push(f);for(var k=0;k<this.C.length;k++)try{this.C[k](f)}catch(l){}};bt.prototype.listen=function(a){this.C.push(a)};
    bt.prototype.get=function(){for(var a={},b=0;b<this.messages.length;b++){var c=this.messages[b],d=a[c.notBeforeEventId];d||(d=[],a[c.notBeforeEventId]=d);d.push(c)}return a};bt.prototype.prune=function(a){for(var b=[],c=[],d=0;d<this.messages.length;d++){var e=this.messages[d];e.notBeforeEventId===a?b.push(e):c.push(e)}this.messages=c;return b};function ct(a,b,c){c.eventMetadata=c.eventMetadata||{};c.eventMetadata.source_canonical_id=Rf.canonicalContainerId;dt().enqueue(a,b,c)}
    function et(){var a=ft;dt().listen(a)}function dt(){var a=ue.mb;a||(a=new bt,ue.mb=a);return a};var gt={},ht={};function it(a,b){for(var c=[],d=[],e={},f=0;f<a.length;e={ig:void 0,Rf:void 0},f++){var k=a[f];if(k.indexOf("-")>=0){if(e.ig=uh(k,b),e.ig){var l=Vf();Xa(l,function(r){return function(t){return r.ig.da===t}}(e))?c.push(k):d.push(k)}}else{var m=gt[k]||[];e.Rf={};m.forEach(function(r){return function(t){r.Rf[t]=!0}}(e));for(var n=Sf(),p=0;p<n.length;p++)if(e.Rf[n[p]]){c=c.concat(Vf());break}var q=ht[k]||[];q.length&&(c=c.concat(q))}}return{jl:c,nl:d}}
    function jt(a){ab(gt,function(b,c){var d=c.indexOf(a);d>=0&&c.splice(d,1)})}function kt(a){ab(ht,function(b,c){var d=c.indexOf(a);d>=0&&c.splice(d,1)})}var lt="HA GF G UA AW DC MC".split(" "),mt=!1,nt=!1,ot=!1,pt=!1;function qt(a,b){a.hasOwnProperty("gtm.uniqueEventId")||Object.defineProperty(a,"gtm.uniqueEventId",{value:Ke()});b.eventId=a["gtm.uniqueEventId"];b.priorityId=a["gtm.priorityId"];return{eventId:b.eventId,priorityId:b.priorityId}}var rt=void 0,st=void 0;
    function tt(a,b,c){var d=g(a);d.eventId=void 0;d.inheritParentConfig=void 0;Object.keys(b).some(function(f){return b[f]!==void 0})&&K(136);var e=g(b);g(c,e);ct(Zs(Sf()[0],e),a.eventId,d)}function ut(a){for(var b=ia([L.g.yc,L.g.Cb]),c=b.next();!c.done;c=b.next()){var d=c.value,e=a&&a[d]||wi.D[d];if(e)return e}}
    var vt=[L.g.yc,L.g.Cb,L.g.rc,L.g.fb,L.g.ob,L.g.ya,L.g.qa,L.g.Ka,L.g.Oa,L.g.ub],wt={config:function(a,b){var c=qt(a,b);if(!(a.length<2)&&h(a[1])){var d={};if(a.length>2){if(a[2]!=void 0&&!Ha(a[2])||a.length>3)return;d=a[2]}var e=uh(a[1],b.isGtmEvent);if(e){var f,k,l;a:{if(!Pf.od){var m=cg(dg());if(mg(m)){var n=m.parent,p=n.isDestination;l={tl:cg(n),il:p};break a}}l=void 0}var q=l;q&&(f=q.tl,k=q.il);us(c.eventId,"gtag.config");var r=e.da,t=e.id!==r;if(t?Vf().indexOf(r)===-1:Sf().indexOf(r)===-1){if(!b.inheritParentConfig&&
    !d[L.g.yb]){var u=ut(d);if(t)Nr(r,u,{source:2,fromContainerExecution:b.fromContainerExecution});else if(f!==void 0&&f.containers.indexOf(r)!==-1){var v=d;rt?tt(b,v,rt):st||(st=g(v))}else Lr(r,u,!0,{source:2,fromContainerExecution:b.fromContainerExecution})}}else{if(f&&(K(128),k&&K(130),b.inheritParentConfig)){var w=d;st?tt(b,st,w):!w[L.g.Rb]&&ye&&rt||(rt=g(w));return}var x=d;if(!ot&&(ot=!0,nt))for(var y=ia(vt),z=y.next();!z.done;z=y.next())if(x.hasOwnProperty(z.value)){Eg("erc");break}Af&&!Qf&&(Ys===
    1&&(qg.mcc=!1),Ys=2);Bg=!0;if(ye&&!t&&!d[L.g.Rb]){var A=pt;pt=!0;if(A)return}mt||K(43);if(!b.noTargetGroup)if(t){kt(e.id);var C=e.id,G=d[L.g.he]||"default";G=String(G).split(",");for(var D=0;D<G.length;D++){var F=ht[G[D]]||[];ht[G[D]]=F;F.indexOf(C)<0&&F.push(C)}}else{jt(e.id);var J=e.id,I=d[L.g.he]||"default";I=I.toString().split(",");for(var Q=0;Q<I.length;Q++){var R=gt[I[Q]]||[];gt[I[Q]]=R;R.indexOf(J)<0&&R.push(J)}}delete d[L.g.he];var X=b.eventMetadata||{};X.hasOwnProperty("is_external_event")||
    (X.is_external_event=!b.fromContainerExecution);b.eventMetadata=X;delete d[L.g.ed];for(var W=t?[e.id]:Vf(),O=0;O<W.length;O++){var ja=d,da=W[O],Y=g(b),pa=uh(da,Y.isGtmEvent);pa&&wi.push("config",[ja],pa,Y)}}}}},consent:function(a,b){if(a.length===3){K(39);var c=qt(a,b),d=a[1],e=a[2];b.fromContainerExecution||(e[L.g.M]&&K(139),e[L.g.na]&&K(140));d==="default"?kh(e):d==="update"?lh(e,c):d==="declare"&&b.fromContainerExecution&&jh(e)}},event:function(a,b){var c=a[1];if(!(a.length<2)&&h(c)){var d;if(a.length>
    2){if(!Ha(a[2])&&a[2]!=void 0||a.length>3)return;d=a[2]}var e=d,f={},k=(f.event=c,f);e&&(k.eventModel=g(e),e[L.g.ed]&&(k.eventCallback=e[L.g.ed]),e[L.g.de]&&(k.eventTimeout=e[L.g.de]));var l=qt(a,b),m=l.eventId,n=l.priorityId;k["gtm.uniqueEventId"]=m;n&&(k["gtm.priorityId"]=n);if(c==="optimize.callback")return k.eventModel=k.eventModel||{},k;var p;var q=d,r=q&&q[L.g.Qb];r===void 0&&(r=Te(L.g.Qb,2),r===void 0&&(r="default"));if(h(r)||Array.isArray(r)){var t;t=b.isGtmEvent?h(r)?[r]:r:r.toString().replace(/\s+/g,
    "").split(",");var u=it(t,b.isGtmEvent),v=u.jl,w=u.nl;if(w.length)for(var x=ut(q),y=0;y<w.length;y++){var z=uh(w[y],b.isGtmEvent);z&&Nr(z.da,x,{source:3,fromContainerExecution:b.fromContainerExecution})}p=vh(v,b.isGtmEvent)}else p=void 0;var A=p;if(A){var C;!A.length||((C=b.eventMetadata)==null?0:C.em_event)||(nt=!0);us(m,c);for(var G=[],D=0;D<A.length;D++){var F=A[D],J=g(b);if(lt.indexOf(eg(F.prefix))!==-1){var I=g(d),Q=J.eventMetadata||{};Q.hasOwnProperty("is_external_event")||(Q.is_external_event=
    !J.fromContainerExecution);J.eventMetadata=Q;delete I[L.g.ed];vi(c,I,F.id,J);Af&&!Qf&&Ys===0&&(sg("mcc","1"),Ys=1);Bg=!0}G.push(F.id)}k.eventModel=k.eventModel||{};A.length>0?k.eventModel[L.g.Qb]=G.join():delete k.eventModel[L.g.Qb];mt||K(43);b.noGtmEvent===void 0&&b.eventMetadata&&b.eventMetadata.syn_or_mod&&(b.noGtmEvent=!0);k.eventModel[L.g.xb]&&(b.noGtmEvent=!0);return b.noGtmEvent?void 0:k}}},get:function(a,b){K(53);if(a.length===4&&h(a[1])&&h(a[2])&&Ua(a[3])){var c=uh(a[1],b.isGtmEvent),d=String(a[2]),
    e=a[3];if(c){mt||K(43);var f=ut();if(!Xa(Vf(),function(l){return c.da===l}))Nr(c.da,f,{source:4,fromContainerExecution:b.fromContainerExecution});else if(lt.indexOf(eg(c.prefix))!==-1){Bg=!0;qt(a,b);var k={};g((k[L.g.kb]=d,k[L.g.vb]=e,k));xi(d,function(l){H(function(){return e(l)})},c.id,b)}}}},js:function(a,b){if(a.length==2&&a[1].getTime){mt=!0;var c=qt(a,b),d=c.eventId,e=c.priorityId,f={};return f.event="gtm.js",f["gtm.start"]=a[1].getTime(),f["gtm.uniqueEventId"]=d,f["gtm.priorityId"]=e,f}},policy:function(){},
    set:function(a,b){var c;a.length==2&&Ha(a[1])?c=g(a[1]):a.length==3&&h(a[1])&&(c={},Ha(a[2])||Array.isArray(a[2])?c[a[1]]=g(a[2]):c[a[1]]=a[2]);if(c){var d=qt(a,b),e=d.eventId,f=d.priorityId;g(c);var k=g(c);wi.push("set",[k],void 0,b);c["gtm.uniqueEventId"]=e;f&&(c["gtm.priorityId"]=f);delete c.event;b.overwriteModelFields=!0;return c}}},xt={policy:!0};var At=function(a){if(zt(a))return a;this.value=a};At.prototype.getUntrustedMessageValue=function(){return this.value};var zt=function(a){return!a||Ea(a)!=="object"||Ha(a)?!1:"getUntrustedMessageValue"in a};At.prototype.getUntrustedMessageValue=At.prototype.getUntrustedMessageValue;var Bt=!1,Ct=[];function Dt(){if(!Bt){Bt=!0;for(var a=0;a<Ct.length;a++)H(Ct[a])}}function Et(a){Bt?H(a):Ct.push(a)};var Ft=0,Gt={},Ht=[],It=[],Jt=!1,Kt=!1;function Lt(a,b){return a.messageContext.eventId-b.messageContext.eventId||a.messageContext.priorityId-b.messageContext.priorityId}var Mt=function(a){B[te.Ya].push(a)};function Nt(a,b){var c=a._clear||b.overwriteModelFields;ab(a,function(e,f){e!=="_clear"&&(c&&We(e),We(e,f))});Ge||(Ge=a["gtm.start"]);var d=a["gtm.uniqueEventId"];if(!a.event)return!1;typeof d!=="number"&&(d=Ke(),a["gtm.uniqueEventId"]=d,We("gtm.uniqueEventId",d));return Xs(a)}
    function Ot(a){if(a==null||typeof a!=="object")return!1;if(a.event)return!0;if(bb(a)){var b=a[0];if(b==="config"||b==="event"||b==="js"||b==="get")return!0}return!1}
    function Pt(){var a;if(It.length)a=It.shift();else if(Ht.length)a=Ht.shift();else return;var b;var c=a;if(Jt||!Ot(c.message))b=c;else{Jt=!0;var d=c.message["gtm.uniqueEventId"];typeof d!=="number"&&(d=c.message["gtm.uniqueEventId"]=Ke());var e={},f={message:(e.event="gtm.init_consent",e["gtm.uniqueEventId"]=d-2,e),messageContext:{eventId:d-2}},k={},l={message:(k.event="gtm.init",k["gtm.uniqueEventId"]=d-1,k),messageContext:{eventId:d-1}};Ht.unshift(l,c);if(Af){if(!P(78)){var m=Pr();m&&Or.push(m)}wg()}b=
    f}return b}
    function Qt(){for(var a=!1,b;!Kt&&(b=Pt());){Kt=!0;delete Qe.eventModel;Se();var c=b,d=c.message,e=c.messageContext;if(d==null)Kt=!1;else{if(e.fromContainerExecution)for(var f=["gtm.allowlist","gtm.blocklist","gtm.whitelist","gtm.blacklist","tagTypeBlacklist"],k=0;k<f.length;k++){var l=f[k],m=Te(l,1);if(Array.isArray(m)||Ha(m))m=g(m);Re[l]=m}try{if(Ua(d))try{d.call(Ue)}catch(z){}else if(Array.isArray(d)){var n=d;if(h(n[0])){var p=n[0].split("."),q=p.pop(),r=n.slice(1),t=Te(p.join("."),2);if(t!=null)try{t[q].apply(t,
    r)}catch(z){}}}else{var u=void 0;if(bb(d))a:{if(d.length&&h(d[0])){var v=wt[d[0]];if(v&&(!e.fromContainerExecution||!xt[d[0]])){u=v(d,e);break a}}u=void 0}else u=d;u&&(a=Nt(u,e)||a)}}finally{e.fromContainerExecution&&Se(!0);var w=d["gtm.uniqueEventId"];if(typeof w==="number"){for(var x=Gt[String(w)]||[],y=0;y<x.length;y++)It.push(Rt(x[y]));x.length&&It.sort(Lt);delete Gt[String(w)];w>Ft&&(Ft=w)}Kt=!1}}}return!a}
    function St(){if(P(63)){var a=Tt();}var b=Qt();if(P(63)){}try{var c=Xf(),d=B[te.Ya].hide;if(d&&d[c]!==void 0&&d.end){d[c]=!1;var e=!0,f;for(f in d)if(d.hasOwnProperty(f)&&d[f]===
    !0){e=!1;break}e&&(d.end(),d.end=null)}}catch(k){}return b}function ft(a){if(Ft<a.notBeforeEventId){var b=String(a.notBeforeEventId);Gt[b]=Gt[b]||[];Gt[b].push(a)}else It.push(Rt(a)),It.sort(Lt),H(function(){Kt||Qt()})}function Rt(a){return{message:a.message,messageContext:a.messageContext}}
    var Ut=function(){function a(f){var k={};if(zt(f)){var l=f;f=zt(l)?l.getUntrustedMessageValue():void 0;k.fromContainerExecution=!0}return{message:f,messageContext:k}}var b=Jb(te.Ya,[]),c=ue[te.Ya]=ue[te.Ya]||{};c.pruned===!0&&K(83);Gt=dt().get();et();Xr(function(){if(!c.gtmDom){c.gtmDom=!0;var f={};b.push((f.event="gtm.dom",f))}});Et(function(){if(!c.gtmLoad){c.gtmLoad=!0;var f={};b.push((f.event="gtm.load",f))}});c.subscribers=(c.subscribers||0)+1;var d=b.push;b.push=function(){var f;if(ue.SANDBOXED_JS_SEMAPHORE>
    0){f=[];for(var k=0;k<arguments.length;k++)f[k]=new At(arguments[k])}else f=[].slice.call(arguments,0);var l=f.map(function(q){return a(q)});Ht.push.apply(Ht,l);var m=d.apply(b,f),n=Math.max(100,Number("1000")||300);if(this.length>n)for(K(4),c.pruned=!0;this.length>n;)this.shift();var p=typeof m!=="boolean"||m;return Qt()&&p};var e=b.slice(0).map(function(f){return a(f)});Ht.push.apply(Ht,e);if(Tt()){if(P(63)){}H(St)}},Tt=function(){var a=!0;return a};function Vt(a){if(a==null||a.length===0)return!1;var b=Number(a),c=hb();return b<c+3E5&&b>c-9E5};
    var pu=function(){};var qu=function(){};qu.prototype.toString=function(){return"undefined"};var ru=new qu;function yu(a,b){function c(k){var l=kf(k),m=df(l,"protocol"),n=df(l,"host",!0),p=df(l,"port"),q=df(l,"path").toLowerCase().replace(/\/$/,"");if(m===void 0||m==="http"&&p==="80"||m==="https"&&p==="443")m="web",p="default";return[m,n,p,q]}for(var d=c(String(a)),e=c(String(b)),f=0;f<d.length;f++)if(d[f]!==e[f])return!1;return!0}
    function zu(a){return Au(a)?1:0}
    function Au(a){var b=a.arg0,c=a.arg1;if(a.any_of&&Array.isArray(c)){for(var d=0;d<c.length;d++){var e=g(a,{});g({arg1:c[d],any_of:void 0},e);if(zu(e))return!0}return!1}switch(a["function"]){case "_cn":return String(b).indexOf(String(c))>=0;case "_css":var f;a:{if(b)try{for(var k=0;k<rd.length;k++){var l=rd[k];if(b[l]){f=b[l](c);break a}}}catch(v){}f=!1}return f;case "_ew":var m,n;m=String(b);n=String(c);var p=m.length-n.length;return p>=0&&m.indexOf(n,p)===p;case "_eq":return String(b)===String(c);
    case "_ge":return Number(b)>=Number(c);case "_gt":return Number(b)>Number(c);case "_lc":return String(b).split(",").indexOf(String(c))>=0;case "_le":return Number(b)<=Number(c);case "_lt":return Number(b)<Number(c);case "_re":var q;a:{var r=a.ignore_case?"i":void 0;try{var t=String(c)+r,u=sd.get(t);u||(u=new RegExp(c,r),sd.set(t,u));q=u.test(b);break a}catch(v){q=!1;break a}}return q;case "_sw":return mb(String(b),String(c));case "_um":return yu(b,c)}return!1};function Bu(){var a;a=a===void 0?"":a;var b,c;return((b=data)==null?0:(c=b.blob)==null?0:c.hasOwnProperty(1))?String(data.blob[1]):a};function Cu(){var a=[["cv",P(85)?Bu():"1"],["rv",te.zf],["tc",Kc.filter(function(b){return b}).length]];te.qd&&a.push(["x",te.qd]);Me.C&&a.push(["tag_exp",Me.C]);return a};var Du={},Eu={};function Fu(){var a=0;return function(b){switch(b){case 1:a|=1;break;case 2:a|=2;break;case 3:a|=4}return a}}function Gu(a,b,c){if(zf){var d=String(c)+b;Du[a]=Du[a]||[];Du[a].push(d);Eu[a]=Eu[a]||[];Eu[a].push("j"+b)}}function Hu(a){var b=a.eventId,c=a.Ib,d=[],e=Du[b]||[];e.length&&d.push(["hf",e.join(".")]);var f=Eu[b]||[];f.length&&d.push(["ht",f.join(".")]);c&&(delete Du[b],delete Eu[b]);return d};function Uu(a){var b=ue.zones;return b?b.getIsAllowedFn(Sf(),a):function(){return!0}}
    function Vu(){Ar(function(a){var b=a.originalEventData["gtm.uniqueEventId"],c=ue.zones;return c?c.isActive(Sf(),b):!0});yr(function(a){var b,c;b=a.entityId;c=a.securityGroups;return Uu(Number(a.originalEventData["gtm.uniqueEventId"]))(b,c)})};var Gv=function(){var a=!0;Aj(7)&&Aj(9)&&Aj(10)||(a=!1);return a};Kg();function Iw(){return B.gaGlobal=B.gaGlobal||{}}var Jw=function(){var a=Iw();a.hid=a.hid||Ya();return a.hid},Kw=function(a,b){var c=Iw();if(c.vid==void 0||b&&!c.from_cookie)c.vid=a,c.from_cookie=b};var ux=function(a){this.D=a;this.F="";this.C=this.D},vx=function(a,b){a.C=b;return a},wx=function(a,b){a.J=b;return a};function xx(a){var b=a.search;return a.protocol+"//"+a.hostname+a.pathname+(b?b+"&richsstsse":"?richsstsse")}function yx(a,b,c){if(a){var d=a||[];if(Array.isArray(d))for(var e=Ha(b)?b:{},f=ia(d),k=f.next();!k.done;k=f.next())c(k.value,e)}};var hy=window,iy=document,jy=function(a){var b=hy._gaUserPrefs;if(b&&b.ioo&&b.ioo()||iy.documentElement.hasAttribute("data-google-analytics-opt-out")||a&&hy["ga-disable-"+a]===!0)return!0;try{var c=hy.external;if(c&&c._gaUserPrefs&&c._gaUserPrefs=="oo")return!0}catch(p){}for(var d=[],e=String(iy.cookie).split(";"),f=0;f<e.length;f++){var k=e[f].split("="),l=k[0].replace(/^\s*|\s*$/g,"");if(l&&l=="AMP_TOKEN"){var m=k.slice(1).join("=").replace(/^\s*|\s*$/g,"");m&&(m=decodeURIComponent(m));d.push(m)}}for(var n=
    0;n<d.length;n++)if(d[n]=="$OPT_OUT")return!0;return iy.getElementById("__gaOptOutExtension")?!0:!1};
    function ty(a){ab(a,function(c){c.charAt(0)==="_"&&delete a[c]});var b=a[L.g.Ua]||{};ab(b,function(c){c.charAt(0)==="_"&&delete b[c]})};var Xy=function(a,b){};function Wy(a,b){var c=function(){};return c}
    function Yy(a,b,c){};var Zy=Wy;var az=encodeURI,U=encodeURIComponent,bz=Array.isArray,cz=function(a,b,c){
    Sb(a,b,c)},dz=function(a,b){if(!a)return!1;var c=df(kf(a),"host");if(!c)return!1;for(var d=0;b&&d<b.length;d++){var e=b[d]&&b[d].toLowerCase();if(e){var f=c.length-e.length;f>0&&e.charAt(0)!="."&&(f--,e="."+e);if(f>=0&&c.indexOf(e,f)==f)return!0}}return!1},ez=function(a,b,c){for(var d={},e=!1,f=0;a&&f<a.length;f++)a[f]&&a[f].hasOwnProperty(b)&&a[f].hasOwnProperty(c)&&(d[a[f][b]]=a[f][c],e=!0);return e?d:null};
    var nz=B.clearTimeout,oz=B.setTimeout,V=function(a,b,c){if(Rj()){b&&H(b)}else return Pb(a,b,c)},pz=function(){return B.location.href},qz=function(a,b){return Te(a,b||2)},rz=function(a,b){B[a]=b},sz=function(a,b,c){b&&(B[a]===void 0||c&&!B[a])&&(B[a]=b);return B[a]},tz=function(a,b){if(Rj()){b&&H(b)}else Rb(a,b)};
    var uz={};var Z={securityGroups:{}};
    
    Z.securityGroups.v=["google"],Z.__v=function(a){var b=a.vtp_name;if(!b||!b.replace)return!1;var c=qz(b.replace(/\\\./g,"."),a.vtp_dataLayerVersion||1);return c!==void 0?c:a.vtp_defaultValue},Z.__v.o="v",Z.__v.isVendorTemplate=!0,Z.__v.priorityOverride=0,Z.__v.isInfrastructure=!0,Z.__v.runInSiloedMode=!1;
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    Z.securityGroups.get=["google"],Z.__get=function(a){var b=a.vtp_settings,c=b.eventParameters||{},d=String(a.vtp_eventName),e={};e.eventId=a.vtp_gtmEventId;e.priorityId=a.vtp_gtmPriorityId;a.vtp_deferrable&&(e.deferrable=!0);var f=at(String(b.streamId),d,c);ct(f,e.eventId,e);a.vtp_gtmOnSuccess()},Z.__get.o="get",Z.__get.isVendorTemplate=!0,Z.__get.priorityOverride=0,Z.__get.isInfrastructure=!1,Z.__get.runInSiloedMode=!1;
    
    
    var vz={};vz.dataLayer=Ue;vz.callback=function(a){Ie.hasOwnProperty(a)&&Ua(Ie[a])&&Ie[a]();delete Ie[a]};vz.bootstrap=0;vz._spx=!1;
    function wz(){ue[Xf()]=ue[Xf()]||vz;ig();kg()||ab(lg(),function(d,e){Nr(d,e.transportUrl,e.context);K(92)});kb(Je,Z.securityGroups);var a=cg(dg()),b,c=a==null?void 0:(b=a.context)==null?void 0:b.source;c!==2&&c!==4&&c!==3||K(142);Rc=bd}
    (function(a){function b(){n=E.documentElement.getAttribute("data-tag-assistant-present");Vt(n)&&(m=l.Gh)}function c(){m&&Ib?k(m):a()}if(!B["__TAGGY_INSTALLED"]){var d=!1;if(E.referrer){var e=kf(E.referrer);d=ff(e,"host")==="cct.google"}if(!d){var f=dk("googTaggyReferrer");d=!(!f.length||!f[0].length)}d&&(B["__TAGGY_INSTALLED"]=!0,Pb("https://cct.google/taggy/agent.js"))}var k=function(u){var v="GTM",w="GTM";ze&&(v="OGT",w="GTAG");var x=B["google.tagmanager.debugui2.queue"];x||(x=
    [],B["google.tagmanager.debugui2.queue"]=x,Pb("https://"+te.Kc+"/debug/bootstrap?id="+Rf.ctid+"&src="+w+"&cond="+u+"&gtm="+Tj()));var y={messageType:"CONTAINER_STARTING",data:{scriptSource:Ib,containerProduct:v,debug:!1,id:Rf.ctid,targetRef:{ctid:Rf.ctid,isDestination:Pf.od},aliases:Tf(),destinations:Wf()}};y.data.resume=function(){a()};te.Ti&&(y.data.initialPublish=!0);x.push(y)},l={Kj:1,Ih:2,Yh:3,Dg:4,Gh:5};l[l.Kj]="GTM_DEBUG_LEGACY_PARAM";l[l.Ih]="GTM_DEBUG_PARAM";l[l.Yh]="REFERRER";l[l.Dg]="COOKIE";l[l.Gh]="EXTENSION_PARAM";
    var m=void 0,n=void 0,p=df(B.location,"query",!1,void 0,"gtm_debug");Vt(p)&&(m=l.Ih);if(!m&&E.referrer){var q=kf(E.referrer);ff(q,"host")==="tagassistant.google.com"&&(m=l.Yh)}if(!m){var r=dk("__TAG_ASSISTANT");r.length&&r[0].length&&(m=l.Dg)}m||b();if(!m&&n&&n.indexOf("pending:")===0&&Vt(n.substr(8))){var t=!1;Tb(E,"TADebugSignal",function(){t||(t=!0,b(),c())},!1);B.setTimeout(function(){t||(t=!0,b(),c())},200)}else c()})(function(){try{gg();if(P(63)){}Tg().D();tj();rh();var a=Zf();if(Nf().canonical[a]){var b=ue.zones;b&&b.unregisterChild(Sf());zr().removeExternalRestrictions(Zf());}else{a:{}Me.C="95250752";Me.D=Me.F;Me.J="";Me.T="ad_storage|analytics_storage|ad_user_data|ad_personalization";Me.O="ad_storage|analytics_storage|ad_user_data";Me.Ha="";
    Kr();for(var c=data.resource||{},d=c.macros||[],e=0;e<d.length;e++)Hc.push(d[e]);for(var f=c.tags||[],k=0;k<f.length;k++)Kc.push(f[k]);for(var l=c.predicates||[],m=0;m<l.length;m++)Jc.push(l[m]);for(var n=c.rules||[],p=0;p<n.length;p++){for(var q=n[p],r={},t=0;t<q.length;t++){var u=q[t][0];r[u]=Array.prototype.slice.call(q[t],1);u!=="if"&&u!=="unless"||Qc(r[u])}Ic.push(r)}Mc=Z;Nc=zu;wz();if(!De)for(var v=Gg["6"]!==!1?Oe(Me.O):Oe(Me.T),w=0;w<gh.length;w++){var x=gh[w],y=x,z=v[x]?"granted":"denied";
    Ng().implicit(y,z)}Ut();Sr=!1;Tr=0;if(E.readyState==="interactive"&&!E.createEventObject||E.readyState==="complete")Vr();else{Tb(E,"DOMContentLoaded",Vr);Tb(E,"readystatechange",Vr);if(E.createEventObject&&E.documentElement.doScroll){var A=!0;try{A=!B.frameElement}catch(Ka){}A&&Wr()}Tb(B,"load",Vr)}Bt=!1;E.readyState==="complete"?Dt():Tb(B,"load",Dt);
    zf&&(ei(ri),B.setInterval(qi,864E5),ei(Cu),ei(vs),ei(oq),ei(ui),ei(Hu),ei(Gs),ei(bp),P(72)&&(ei(As),ei(Bs),ei(Cs)));if(Af){Ag();Lh();Rr();var G;var D=cg(dg());if(D){for(;D.parent;){var F=cg(D.parent);if(!F)break;D=F}G=D}else G=void 0;var J=G;if(!J)K(144);else if(J.canonicalContainerId){var I;a:{if(J.scriptSource){var Q;try{var R;Q=(R=ec())==null?void 0:R.getEntriesByType("resource")}catch(Ka){}if(Q){for(var X={},W=0;W<Q.length;++W){var O=Q[W],ja=O.initiatorType;if(ja==="script"&&O.name===J.scriptSource){I=
    {Il:W,Jl:X};break a}X[ja]=1+(X[ja]||0)}K(146)}else K(145)}I=void 0}var da=I;da&&(sg("rtg",String(J.canonicalContainerId)),sg("rlo",String(da.Il)),sg("slo",String(da.Jl.script||"0")),P(67)&&(sg("hlo",J.htmlLoadOrder||Ef(J)),sg("lst",String(J.loadScriptType||Ff(J)))))}var Y;var pa=bg();if(pa){var Fa;Y=pa.canonicalContainerId||"_"+(pa.scriptContainerId||((Fa=pa.destinations)==null?void 0:Fa[0]))}else Y=void 0;var ta=Y;ta&&sg("pcid",ta);P(30)&&(sg("bt",String(Me.F?2:Be?1:0)),sg("ct",String(Me.F?0:Be?
    1:Rj()?2:3)))}pu();Fg(1);Vu();He=hb();vz.bootstrap=He;if(P(63)){}}}catch(Ka){if(Fg(4),zf){var Da=li(!0,!0);Sb(Da)}}});
    
    
    })()
    